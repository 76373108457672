import { ContentContainer, SectionHeader, Stage } from "components/layout";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { AccountSettings } from "./account-settings/account-settings";
import { AgencySettings } from "./agency-settings/agency-settings";
import { AgencySettingsWorkflow } from "./agency-settings/agency-settings-workflow";
import { ManageIntegrations } from "./manage-integrations/manage-integrations";
import { SettingsNavigation } from "./manage-nav";
import { ManageUsers } from "./manage-users/manage-users";
import { TemplatesIndex } from "./templates/templates";

export const ManageRoot: React.FC<unknown> = () => {
    return (
        <Stage>
            <SectionHeader title="Settings" />
            <SettingsNavigation />
            <ContentContainer>
                <Switch>
                    <Route path="/settings/account" component={AccountSettings} />
                    <Route path="/settings/templates" component={TemplatesIndex} />
                    <Route path="/settings/agency" component={AgencySettings} />
                    <Route
                        exact
                        path="/settings/workflow"
                        component={AgencySettingsWorkflow}
                    />
                    <Route
                        exact
                        path="/settings/workflow/:workflowId"
                        component={AgencySettingsWorkflow}
                    />
                    <Route path="/settings/users" component={ManageUsers} />
                    <Route path="/settings/integrations" component={ManageIntegrations} />
                    <Route
                        path="/settings"
                        render={() => <Redirect to="/settings/account" />}
                    />
                </Switch>
            </ContentContainer>
        </Stage>
    );
};
