import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/20/solid";
import { NoteEntityId, NoteId, ago } from "@sp-crm/core";
import { AuthorName } from "components/byline/author-name";
import { EditNote, IEntityNote } from "components/notes/edit";
import { DeleteButton, EditButton } from "components/ui/action-button";
import { Spinner } from "components/ui/spinner";
import {
    GetNotesForEntityQuery,
    UpdateNoteMutation,
    UpdateNoteMutationVariables,
    useDeleteNoteMutation,
    useUpdateNoteMutation,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { breakNewlines } from "util/text";

interface Props {
    note: GetNotesForEntityQuery["getNotesForEntity"][0];
    refetch: () => void;
    entityId: NoteEntityId;
    onDeleted: (noteId: NoteId) => void;
    onUpdated: (note: UpdateNoteMutation["updateNote"]) => void;
}

export const ActivityFeedNote: React.FC<Props> = props => {
    const { note, entityId, refetch, onDeleted, onUpdated } = props;
    const [isEditing, setIsEditing] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const enableEdit = useCallback(() => setIsEditing(true), []);
    const disableEdit = useCallback(() => setIsEditing(false), []);
    const deleteMutation = useDeleteNoteMutation();
    const deleteCallback = useCallback(async () => {
        setIsDeleting(true);
        await deleteMutation.mutateAsync({
            noteId: note._id,
            entityType: "dontcare",
            entityId,
        });
        setIsDeleting(false);
        onDeleted(note._id);
        refetch();
    }, [deleteMutation, note._id, entityId, refetch, onDeleted]);
    const updateMutation = useUpdateNoteMutation();
    const updateCallback = useCallback(
        async (noteId: NoteId, text: string, dateAdded: Date) => {
            const payload: UpdateNoteMutationVariables = {
                entityId,
                noteId: noteId,
                params: {
                    text: text,
                    isPublished: true,
                    dateAddedOverride: dateAdded,
                },
            };
            const result = await updateMutation.mutateAsync(payload);
            onUpdated(result.updateNote);
            disableEdit();
            refetch();
        },
        [updateMutation, entityId, disableEdit, refetch, onUpdated],
    );
    const editableNote: IEntityNote = useMemo(() => {
        return {
            _id: note._id,
            authorId: note.authorId,
            text: note.text,
            dateAdded: new Date(note.dateAdded),
            dateAddedManualOverride: new Date(note.dateAdded),
            wasEdited: note.wasEdited,
        };
    }, [note]);
    return (
        <div className="relative flex items-start space-x-3">
            <div>
                <div className="relative px-1">
                    <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                        <ChatBubbleLeftEllipsisIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                        />
                    </div>
                </div>
            </div>
            <div className="min-w-0 flex-1 group">
                {isEditing ? (
                    <EditNote
                        onEditNote={updateCallback}
                        note={editableNote}
                        leaveEditMode={disableEdit}
                    />
                ) : (
                    <div className="space-y-2">
                        <div>
                            <div className="text-sm">
                                <span className="font-medium text-gray-900">
                                    <AuthorName authorId={note.authorId} />
                                </span>
                            </div>
                            <p className="mt-0.5 text-sm text-gray-500">
                                {ago(note.dateAdded, "")}
                                {note.wasEdited ? " (edited)" : ""}
                            </p>
                        </div>
                        <div className="text-base text-gray-900">
                            <p>{breakNewlines(note.text)}</p>
                        </div>
                        {isDeleting ? (
                            <div className="flex space-x-2 items-center">
                                <span className="text-sm italic text-gray-600">
                                    Deleting...
                                </span>
                                <Spinner />
                            </div>
                        ) : (
                            <div className="flex space-x-2 text-gray-300 group-hover:text-gray-700">
                                <EditButton
                                    backgroundColor="bg-gray-200"
                                    onClick={enableEdit}
                                />

                                <DeleteButton
                                    confirm={{
                                        title: "Delete note?",
                                        message:
                                            "Are you sure you want to delete this note?",
                                    }}
                                    backgroundColor="bg-gray-200"
                                    onClick={deleteCallback}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
