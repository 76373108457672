import { CustomListItemId, CustomListKey } from "@sp-crm/core";
import { SupportEmailLink } from "components/app/support-email-link";
import { CustomListMultiselect } from "components/shared/custom-list-multiselect";
import { AutosavingInput } from "components/ui/autosaving-input";
import { InlineBanner } from "components/ui/inline-banner";
import {
    MessageTrackingLevel,
    WorkflowEventType,
    useGetMessagingConfigurationQuery,
} from "generated/graphql";
import { produce } from "immer";
import React from "react";
import { stableQueryOptions } from "util/requests";
import { EditableWorkflow } from "./types";

const findClientStatusFieldMatcher = (workflow: EditableWorkflow) => {
    return workflow?.trigger?.emailOpened?.entityFieldMatchers?.find(
        m => m.fieldName === "statusListItem",
    );
};

export const hasEmailOpenedTrigger = (workflow: EditableWorkflow): boolean => {
    return workflow.trigger?.eventType === WorkflowEventType.EmailOpened;
};

export const hasScheduleTrigger = (workflow: EditableWorkflow): boolean => {
    return workflow.trigger?.eventType === WorkflowEventType.Schedule;
};

interface EmailOpenedTriggerEditorProps {
    workflow: EditableWorkflow;
    onChange: (newWorkflow: EditableWorkflow) => void;
}

export const EmailOpenedTriggerEditor: React.FC<
    EmailOpenedTriggerEditorProps
> = props => {
    const { workflow, onChange } = props;

    const getMessagingConfiguration = useGetMessagingConfigurationQuery(
        {},
        stableQueryOptions(),
    );

    const handleChange = (selectedIds: CustomListItemId[]) => {
        const newWorkflow = produce(workflow, draft => {
            const matcher = findClientStatusFieldMatcher(draft);

            if (selectedIds.length > 0) {
                if (matcher) {
                    matcher.fieldName = "statusListItem";
                    matcher.current.values = selectedIds;
                } else {
                    draft.trigger.emailOpened!.entityFieldMatchers.push({
                        fieldName: "statusListItem",
                        current: {
                            values: selectedIds,
                        },
                    });
                }
            } else {
                draft.trigger.emailOpened!.entityFieldMatchers =
                    draft.trigger.emailOpened!.entityFieldMatchers.filter(
                        m => m.fieldName !== "statusListItem",
                    );
            }
        });

        onChange(newWorkflow);
    };

    const trigger = workflow.trigger?.emailOpened;

    const fieldMatcher = findClientStatusFieldMatcher(workflow);

    if (!trigger) {
        return null;
    }

    return (
        <div className="space-y-2">
            {getMessagingConfiguration.data?.getMessagingConfiguration?.tenant
                .trackingLevel === MessageTrackingLevel.Message ? (
                <InlineBanner type="warning">
                    This workflow is optimized for agencies that have per-recipient
                    tracking enabled for email. We recommend contacting{" "}
                    <SupportEmailLink /> to upgrade your email tracking.
                </InlineBanner>
            ) : null}
            <div>
                <p>Client status filter</p>
                <CustomListMultiselect
                    disabled={!workflow.regionVisibility?.editable}
                    customListKey={CustomListKey.ClientStatus}
                    value={fieldMatcher?.current?.values || []}
                    onChange={handleChange}
                />
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    Optionally filter email open workflows to these client statuses. Leave
                    empty to trigger on any status.
                </div>
            </div>
            <div>
                <AutosavingInput
                    label="Inactivity threshold (days)"
                    type="number"
                    min="0"
                    step="1"
                    disabled={!workflow.regionVisibility?.editable}
                    initial={(trigger.activityThresholdDays || 0).toString()}
                    onCommit={value => {
                        onChange(
                            produce(workflow, draft => {
                                const newValue = parseInt(value, 10);

                                draft.trigger.emailOpened!.activityThresholdDays = isNaN(
                                    newValue,
                                )
                                    ? 0
                                    : newValue;
                            }),
                        );
                    }}
                />
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    The number of days since the last email open to trigger the workflow.
                    If a recipient has already opened the email within this threshold, the
                    workflow will not trigger.
                </div>
            </div>
        </div>
    );
};
