import { UserId } from "@sp-crm/core";
import { useDebouncedState } from "components/shared/hooks";
import { UserSelect } from "components/shared/user-select";
import { SearchInput } from "components/ui/search-input";
import { SelectPro } from "components/ui/select-pro";
import * as React from "react";
import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCanSeeReferralSourceOwners, useFeature } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import { Actions } from "../../../store/actions";
import { CardSortDropdownValues } from "../../../store/reducers/client-search";

export type EnumDictionary<T extends string | number, U> = {
    [key in T]: U;
};

export enum ClientSearchType {
    CoreNames = "bestName",
    AllNames = "anyName",
    Phone = "phone",
}

const placeholderTextVals: EnumDictionary<ClientSearchType, string> = {
    [ClientSearchType.CoreNames]: "Client or best contact name",
    [ClientSearchType.AllNames]: "Client or any contact name",
    [ClientSearchType.Phone]: "Full or partial phone #",
};

interface Props {
    includeCardSort?: boolean;
}

export const ClientSearch: FunctionComponent<Props> = props => {
    const { includeCardSort } = props;
    const dispatch = useDispatch();
    const isReferralFeatureEnabled = useFeature("clientReferralSourceView");
    const isReferralPreferenceEnabled = useCanSeeReferralSourceOwners();
    const showReferralOption = isReferralFeatureEnabled && isReferralPreferenceEnabled;

    const searchState = useSelector(
        (state: ApplicationState) => state.clientSearch.clientSearchInstance,
    );
    const onUpdateSearchText = React.useCallback(
        (searchText: string) => {
            dispatch({
                type: Actions[Actions.CLIENT_SEARCH_TEXT],
                searchText: (searchText ?? "").trim(),
            });
        },
        [dispatch],
    );

    const onUpdateSearchType = React.useCallback(
        (searchType: ClientSearchType) => {
            dispatch({
                type: Actions[Actions.CLIENT_SEARCH_TYPE],
                searchType: searchType,
            });
        },
        [dispatch],
    );

    const onUpdateSearchUser = React.useCallback(
        (userId: UserId) => {
            dispatch({
                type: Actions[Actions.CLIENTS_LIST_SELECT_USER],
                userId,
            });
        },
        [dispatch],
    );

    const onUpdateReferralUser = React.useCallback(
        (userId: UserId) => {
            dispatch({
                type: Actions[Actions.CLIENTS_LIST_SELECT_REFERRAL_USER],
                userId,
            });
        },
        [dispatch],
    );

    const onUpdateSort = React.useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            dispatch({
                type: Actions[Actions.DASHBOARD_SORT],
                cardSort: e.target.value,
            });
        },
        [dispatch],
    );

    const [searchText, setSearchText, searchTextDebounced] = useDebouncedState(
        searchState.searchText,
    );
    const updateSearchText = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value),
        [setSearchText],
    );
    React.useEffect(() => {
        onUpdateSearchText(searchTextDebounced);
    }, [onUpdateSearchText, searchTextDebounced]);

    return (
        <div className="action-bar md:flex md:justify-between md:space-x-2">
            <div className="space-y-2 md:flex md:space-y-0 md:space-x-2 md:space-y-0">
                <div>
                    <SearchInput
                        className="md:w-64"
                        type="text"
                        onChange={updateSearchText}
                        value={searchText}
                        placeholder={placeholderTextVals[searchState.searchType]}
                    />
                </div>
                <div>
                    <SelectPro
                        onChange={e =>
                            onUpdateSearchType(e.target.value as ClientSearchType)
                        }
                        includePlaceholderOption={false}
                        value={searchState.searchType}
                        options={[
                            {
                                value: ClientSearchType.CoreNames,
                                text: "Search client or best contact name",
                            },
                            {
                                value: ClientSearchType.AllNames,
                                text: "Search client or any contact name",
                            },
                            {
                                value: ClientSearchType.Phone,
                                text: "Search by any contact phone",
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="space-y-2 md:flex md:space-y-0 md:space-x-2">
                {includeCardSort ? (
                    <div>
                        <SelectPro
                            label="Sort by"
                            includePlaceholderOption={false}
                            className="dashboard-sortby"
                            value={searchState.cardSortPreference}
                            options={CardSortDropdownValues}
                            onChange={onUpdateSort}
                        />
                    </div>
                ) : null}
                {showReferralOption ? (
                    <div>
                        <UserSelect
                            label="Filter by referral assignee"
                            onChange={onUpdateReferralUser}
                            value={searchState.referralSourceUserId}
                            includeUnassigned
                            includeEveryone
                        />
                    </div>
                ) : null}
                <div>
                    <UserSelect
                        label="Filter by Employee"
                        onChange={onUpdateSearchUser}
                        value={searchState.selectedUserId}
                        includeUnassigned
                        includeEveryone
                    />
                </div>
            </div>
        </div>
    );
};
