import { CustomListMultiselect } from "components/shared/custom-list-multiselect";
import { AutosavingInput } from "components/ui/autosaving-input";
import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchRelativeDateInterval,
    CustomListKey,
    FieldConditionOperator,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useMemo } from "react";
import { EditableWorkflow } from "./types";

const findInactiveClientStatusCondition = (workflow: EditableWorkflow) => {
    const entityCondition = workflow?.trigger?.schedule?.entityCondition;

    if (
        !entityCondition ||
        entityCondition.nodeType !== AdvancedSearchConditionNodeType.And
    ) {
        return null;
    }

    return entityCondition.children?.find(
        condition =>
            condition.nodeType === AdvancedSearchConditionNodeType.Or &&
            condition.children?.every(
                c =>
                    c.nodeType === AdvancedSearchConditionNodeType.FieldCondition &&
                    c.fieldName === "statusListItem",
            ),
    );
};

const findInactiveClientDateCondition = (workflow: EditableWorkflow) => {
    const entityCondition = workflow?.trigger?.schedule?.entityCondition;

    if (
        !entityCondition ||
        entityCondition.nodeType !== AdvancedSearchConditionNodeType.And
    ) {
        return null;
    }

    return entityCondition.children?.find(
        condition =>
            condition.nodeType === AdvancedSearchConditionNodeType.FieldCondition &&
            condition.fieldName === "updatedAt" &&
            typeof condition.relativeDate?.value === "number",
    );
};

export const hasInactiveClientTrigger = (workflow: EditableWorkflow) => {
    return (
        !!findInactiveClientStatusCondition(workflow) &&
        !!findInactiveClientDateCondition(workflow)
    );
};

interface InactiveClientTriggerEditorProps {
    workflow: EditableWorkflow;
    onChange: (newWorkflow: EditableWorkflow) => void;
}

export const InactiveClientTriggerEditor: React.FC<
    InactiveClientTriggerEditorProps
> = props => {
    const { workflow, onChange } = props;

    const clientStatuses = useMemo(() => {
        const statusCondition = findInactiveClientStatusCondition(workflow);

        if (!statusCondition) {
            return [];
        }

        return statusCondition.children
            .filter(
                condition =>
                    condition.nodeType ===
                        AdvancedSearchConditionNodeType.FieldCondition &&
                    condition.fieldName === "statusListItem",
            )
            .map(condition => condition.textValue);
    }, [workflow]);

    const handleStatusChange = useCallback(
        (newStatuses: string[]) => {
            const newWorkflow = produce(workflow, draft => {
                const statusCondition = findInactiveClientStatusCondition(draft);

                if (!statusCondition) {
                    return;
                }

                statusCondition.children = newStatuses.map(status => ({
                    fieldName: "statusListItem",
                    textValue: status,
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.CustomListEquals,
                }));
            });

            onChange(newWorkflow);
        },
        [workflow, onChange],
    );

    const daysForInactive = useMemo(() => {
        const dateCondition = findInactiveClientDateCondition(workflow);

        if (!dateCondition) {
            return 30;
        }

        return Math.abs(dateCondition.relativeDate.value);
    }, [workflow]);

    const handleDaysChange = useCallback(
        (newDays: string) => {
            const newDaysParsed = parseInt(newDays, 10);

            const newWorkflow = produce(workflow, draft => {
                const dateCondition = findInactiveClientDateCondition(draft);

                if (!dateCondition) {
                    return;
                }

                const newValue = isNaN(newDaysParsed)
                    ? Math.abs(dateCondition.relativeDate.value)
                    : newDaysParsed;

                dateCondition.relativeDate.interval =
                    AdvancedSearchRelativeDateInterval.Day;
                dateCondition.relativeDate.value = -newValue;
            });

            onChange(newWorkflow);
        },
        [workflow, onChange],
    );

    if (!hasInactiveClientTrigger(workflow)) {
        return null;
    }

    return (
        <div className="space-y-2">
            <div>
                <p>Client status filter</p>
                <CustomListMultiselect
                    disabled={!workflow.regionVisibility?.editable}
                    customListKey={CustomListKey.ClientStatus}
                    value={clientStatuses}
                    onChange={handleStatusChange}
                />
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    The workflow will run on inactive clients in any of the selected
                    statuses
                </div>
            </div>
            <div>
                <AutosavingInput
                    label="Days of inactivity"
                    type="number"
                    min={1}
                    initial={`${daysForInactive}`}
                    onCommit={handleDaysChange}
                    disabled={!workflow.regionVisibility?.editable}
                />
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    The workflow will run on clients who have been inactive for the
                    selected number of days.
                </div>
            </div>
        </div>
    );
};
