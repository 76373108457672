import { pdfjs } from "react-pdf";

// @ts-expect-error We are adding this property as a polyfill
if (typeof Promise.withResolvers === "undefined") {
    if (window) {
        // @ts-expect-error We are adding this property as a polyfill
        window.Promise.withResolvers = function () {
            let resolve, reject;
            const promise = new Promise((res, rej) => {
                resolve = res;
                reject = rej;
            });
            return { promise, resolve, reject };
        };
    }
}

export const setupPdfjs = () => {
    // Updated versions of this library should be added to s3://placement-crm-cdn/_static/pdfjs-dist/
    pdfjs.GlobalWorkerOptions.workerSrc = `https://d1alzjajiceuze.cloudfront.net/_static/pdfjs-dist/${pdfjs.version}/legacy/pdf.worker.min.mjs`;
};
