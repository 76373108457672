import { ICommunity } from "@sp-crm/core";
import { Feature } from "components/feature";
import { PhoneInput } from "components/shared/phone-input";
import { AutosavingInput } from "components/ui/autosaving-input";
import { AutosavingStateInput } from "components/ui/autosaving-state-input";
import { Checkbox } from "components/ui/checkbox";
import { UrlButton } from "components/ui/url-button";
import React from "react";
import { useSelector } from "react-redux";
import { currentLocale } from "store/selectors/regions";
import { ApplicationState } from "store/state";

interface CommunityDetailsFieldsProps {
    community: ICommunity;
    onFieldChange: (field: keyof ICommunity, value: string | boolean) => void;
}

export const CommunityDetailsFields: React.FC<CommunityDetailsFieldsProps> = props => {
    const { community, onFieldChange } = props;

    const locale = useSelector((appState: ApplicationState) => currentLocale(appState));

    if (!onFieldChange) {
        throw new Error("CommunityDetailsField.onFieldChange is required");
    }

    return (
        <div className="space-y-2 lg:space-y-4">
            <AutosavingInput
                label="Name"
                initial={community.name}
                onCommit={newVal => onFieldChange("name", newVal)}
            />
            <AutosavingInput
                label="Address"
                initial={community.address}
                onCommit={newVal => onFieldChange("address", newVal)}
            />
            <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                <AutosavingInput
                    label="City"
                    initial={community.city}
                    onCommit={newVal => onFieldChange("city", newVal)}
                />
                <AutosavingStateInput
                    label={locale.strings.state}
                    initial={community.state}
                    onCommit={newVal => onFieldChange("state", newVal)}
                />
                <AutosavingInput
                    label={locale.strings.zip}
                    initial={community.zip}
                    onCommit={newVal => onFieldChange("zip", newVal)}
                />
                <AutosavingInput
                    label="County"
                    initial={community.county}
                    onCommit={newVal => onFieldChange("county", newVal)}
                />
            </div>
            <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                <PhoneInput
                    label="Main phone number"
                    initial={community.mainPhone}
                    onCommit={newVal => onFieldChange("mainPhone", newVal)}
                />
                <PhoneInput
                    label="Fax number"
                    initial={community.faxNumber}
                    onCommit={newVal => onFieldChange("faxNumber", newVal)}
                />
                <AutosavingInput
                    label="Community email"
                    initial={community.email}
                    onCommit={newVal => onFieldChange("email", newVal)}
                />
                <Feature name="emailoptout">
                    <div>
                        <Checkbox
                            label="Email opt-out"
                            checked={community.emailOptOut}
                            onChange={newVal =>
                                onFieldChange("emailOptOut", newVal.target.checked)
                            }
                        />
                        <div className="text-xs text-gray-400">
                            (for bulk/newsletter emails)
                        </div>
                    </div>
                </Feature>
            </div>
            <div className="flex items-center space-x-2 lg:space-x-4">
                <div className="flex-1 flex items-end space-x-1">
                    <AutosavingInput
                        label="Website"
                        initial={community.website}
                        onCommit={newVal => onFieldChange("website", newVal)}
                    />
                    <UrlButton url={community.website} />
                </div>
                <div className="flex-1 flex items-end space-x-1">
                    <AutosavingInput
                        label="Virtual Tour Link"
                        initial={community.virtualTourURL}
                        onCommit={newVal => onFieldChange("virtualTourURL", newVal)}
                    />
                    <UrlButton url={community.virtualTourURL} />
                </div>
            </div>
        </div>
    );
};
