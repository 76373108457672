import { Action } from "redux";
import { Actions } from "../actions";

export interface LoginState {
    loggedIn: boolean;
    loginFailureMessage: string | null;
    loginInProgress: boolean;
}

export function loginReducer(state: LoginState, action: Action): LoginState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        state = {
            loggedIn: false,
            loginInProgress: false,
            loginFailureMessage: null,
        };
    }

    if (action.type === Actions[Actions.LOGIN_START]) {
        state = {
            ...state,
            loginInProgress: true,
        };
    }
    if (action.type === Actions[Actions.LOGIN_FINISH]) {
        state = {
            ...state,
            loggedIn: true,
            loginFailureMessage: null,
        };
    }
    if (action.type === Actions[Actions.LOGIN_FAIL]) {
        const typed = action as { type: string; message: string };
        state = {
            ...state,
            loginFailureMessage: typed.message,
        };
    }

    return state;
}
