import {
    COMMUNITY_REFERRAL,
    ClientId,
    CommunityId,
    Contact,
    ContactId,
    DefaultReferences,
    IContact,
    Maybe,
    ReferenceBusinessId,
    ReferenceContactId,
    ReferenceMap,
    UserId,
    parseEntityId,
} from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContactCard } from "components/contacts/card";
import { Icon } from "components/icon";
import { DeleteButton } from "components/ui/action-button";
import { InlineBanner } from "components/ui/inline-banner";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    AdvancedSearchEntityType,
    useCreateReferenceContactMutation,
    useGetCommunityQuery,
    useGetReferenceBusinessQuery,
    useGetReferenceContactQuery,
} from "generated/graphql";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import {
    useCanSeeReferralSourceOwners,
    useRegionId,
    useUsers,
} from "store/selectors/hooks";
import { NewInlineReference } from "./new-inline-reference";
import { ReferralSourceSearch, ReferralSourceSearchTab } from "./referral-source-search";

interface AssignedToProps {
    userId: UserId;
}

const AssignedTo: React.FC<AssignedToProps> = props => {
    const { userId } = props;

    const userState = useUsers();
    const isReferralPreferenceEnabled = useCanSeeReferralSourceOwners();

    if (!isReferralPreferenceEnabled || !userId || !userState.users[userId]) {
        return null;
    }

    return <p className="text-xs italic">Assigned to: {userState.users[userId].name}</p>;
};

interface ReferenceContactValueProps {
    referenceContactId: ReferenceContactId;
    referenceBusinessId: ReferenceBusinessId | null;
    communityId: CommunityId | null;
    clientId: ClientId;
}

const wrapperStyle = "";

const ReferenceContactValue: React.FC<ReferenceContactValueProps> = props => {
    const { referenceContactId, referenceBusinessId, communityId, clientId } = props;

    const referenceContactQuery = useGetReferenceContactQuery({ id: referenceContactId });

    return (
        <QueryRenderer
            query={referenceContactQuery}
            name="ReferenceContactValue.getReferenceContact">
            {data => {
                if (data.getReferenceContact.deleted) {
                    return (
                        <InlineBanner type="warning">
                            <p className="text-sm">
                                This referral contact has been deleted and its information
                                is no longer available
                            </p>
                        </InlineBanner>
                    );
                }

                const contact = Contact.load(data.getReferenceContact.contactEntity);
                const contactChangedOrganizations =
                    (!!referenceBusinessId &&
                        data.getReferenceContact.business?.id !== referenceBusinessId) ||
                    (!!communityId &&
                        data.getReferenceContact.community?.id !== communityId);

                const newOrganizationName =
                    data.getReferenceContact.business?.businessContactEntity.name ||
                    data.getReferenceContact.community?.name;

                return (
                    <>
                        {contactChangedOrganizations ? (
                            <InlineBanner type="info">
                                <p className="text-sm">
                                    {data.getReferenceContact.contactEntity.name} is now
                                    with {newOrganizationName || "another organization"}
                                </p>
                            </InlineBanner>
                        ) : null}
                        <div className={`${wrapperStyle} md:flex md:space-x-4`}>
                            <div className="pb-4 md:flex-1">
                                <ContactCard
                                    parentEntityType={AdvancedSearchEntityType.Client}
                                    parentId={clientId}
                                    contact={contact}
                                    href={
                                        data.getReferenceContact.deleted
                                            ? null
                                            : `/references/contacts/show/${referenceContactId}`
                                    }
                                    icon="40_ReferralPerson"
                                />
                                <AssignedTo userId={data.getReferenceContact.ownerId} />
                            </div>
                            {referenceBusinessId ? (
                                <div className="md:flex-1">
                                    <ReferenceBusinessValue
                                        referenceId={referenceBusinessId}
                                        clientId={clientId}
                                    />
                                </div>
                            ) : null}
                            {communityId ? (
                                <div className="md:flex-1">
                                    <ReferenceCommunityValue
                                        communityId={communityId}
                                        clientId={clientId}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </>
                );
            }}
        </QueryRenderer>
    );
};

interface ReferenceBusinessValueProps {
    referenceId: ReferenceBusinessId;
    clientId: ClientId;
}

const ReferenceBusinessValue: React.FC<ReferenceBusinessValueProps> = props => {
    const { referenceId, clientId } = props;

    const query = useGetReferenceBusinessQuery({ id: referenceId });

    return (
        <QueryRenderer query={query} name="ReferenceContactValue.getReferenceBusiness">
            {data => {
                if (data.getReferenceBusiness.deleted) {
                    return (
                        <InlineBanner type="warning">
                            <p className="text-sm">
                                This referral organization has been deleted and its
                                information is no longer available
                            </p>
                        </InlineBanner>
                    );
                }

                return (
                    <>
                        <ContactCard
                            parentEntityType={AdvancedSearchEntityType.Client}
                            parentId={clientId}
                            contact={Contact.load(
                                data.getReferenceBusiness.businessContactEntity,
                            )}
                            href={
                                data.getReferenceBusiness.deleted
                                    ? null
                                    : `/references/organizations/show/${referenceId}`
                            }
                            icon="39_Business"
                        />
                        <AssignedTo userId={data.getReferenceBusiness.ownerId} />
                    </>
                );
            }}
        </QueryRenderer>
    );
};

interface ReferenceCommunityValueProps {
    communityId: CommunityId;
    clientId: ClientId;
}

const ReferenceCommunityValue: React.FC<ReferenceCommunityValueProps> = props => {
    const { communityId, clientId } = props;

    const query = useGetCommunityQuery({ id: communityId });

    return (
        <QueryRenderer query={query} name="ReferenceContactValue.getCommunity">
            {data => {
                const communityAsContact: IContact = Contact.load({
                    id: parseEntityId<ContactId>(data.getCommunity.id),
                    name: data.getCommunity.name,
                    address1: data.getCommunity.address,
                    city: data.getCommunity.city,
                    state: data.getCommunity.state,
                    zip: data.getCommunity.zip,
                    phone1: data.getCommunity.mainPhone,
                    website: data.getCommunity.website,
                    email1: data.getCommunity.email,
                });

                return (
                    <ContactCard
                        parentEntityType={AdvancedSearchEntityType.Client}
                        parentId={clientId}
                        contact={communityAsContact}
                        href={`/communities/show/${communityId}`}
                        icon="57_Community"
                    />
                );
            }}
        </QueryRenderer>
    );
};

interface ReferralSourcePickerValueProps {
    value: ReferenceMap;
    clientId: ClientId;
}

const ReferralSourcePickerValue: React.FC<ReferralSourcePickerValueProps> = props => {
    const { value, clientId } = props;

    const identifier = value.identifier.getOrElse("").trim() || null;
    const referenceContactId = value.contact.getOrElse("").trim() || null;
    const referenceBusinessId = value.business.getOrElse("").trim() || null;

    if (identifier) {
        if (identifier === COMMUNITY_REFERRAL) {
            if (referenceContactId) {
                return (
                    <ReferenceContactValue
                        referenceContactId={referenceContactId as ReferenceContactId}
                        referenceBusinessId={null}
                        communityId={referenceBusinessId as CommunityId}
                        clientId={clientId}
                    />
                );
            }

            return (
                <div className={wrapperStyle}>
                    <ReferenceCommunityValue
                        communityId={referenceBusinessId as CommunityId}
                        clientId={clientId}
                    />
                </div>
            );
        }
        return (
            <div>
                {DefaultReferences.find(r => r.key === identifier)?.text || identifier}
            </div>
        );
    }

    if (referenceContactId) {
        return (
            <ReferenceContactValue
                referenceContactId={referenceContactId as ReferenceContactId}
                referenceBusinessId={referenceBusinessId as ReferenceBusinessId}
                communityId={null}
                clientId={clientId}
            />
        );
    }

    if (referenceBusinessId) {
        return (
            <div className={wrapperStyle}>
                <ReferenceBusinessValue
                    referenceId={referenceBusinessId as ReferenceBusinessId}
                    clientId={clientId}
                />
            </div>
        );
    }

    return null;
};

interface ReferralSourcePickerProps {
    value: ReferenceMap;
    onChange: (value: ReferenceMap) => void;
    exclude?: ReferralSourceSearchTab[];
    label?: string;
    showIcon?: boolean;
    clientId: ClientId;
}

const isReferenceMapPopulated = (value: ReferenceMap): boolean => {
    const identifier = value.identifier.getOrElse("").trim() || null;
    const referenceContactId = value.contact.getOrElse("").trim() || null;
    const referenceBusinessId = value.business.getOrElse("").trim() || null;

    return !!identifier || !!referenceContactId || !!referenceBusinessId;
};

export const ReferralSourcePicker: React.FC<ReferralSourcePickerProps> = props => {
    const { value, onChange, exclude, label, clientId } = props;

    const [showModal, setShowModal] = useState<boolean>(false);
    const [inNewRefferalWorkflow, setInNewRefferalWorkflow] = useState(false);

    const [newReferenceContactId, setNewReferenceContactId] =
        useState<ReferenceContactId | null>(null);
    const client = useQueryClient();

    const handleChoose = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            setShowModal(true);
        },
        [setShowModal],
    );

    const clearReferralSource = useCallback(() => {
        onChange({
            identifier: Maybe.none(),
            contact: Maybe.none(),
            business: Maybe.none(),
        });
    }, [onChange]);

    const hide = useCallback(() => {
        client.invalidateQueries("getReferenceContact");
        setInNewRefferalWorkflow(false);
        setNewReferenceContactId(null);
        setShowModal(false);
    }, [setShowModal, client]);

    const handleSearchSelect = useCallback(
        (selected: ReferenceMap) => {
            hide();
            onChange(selected);
        },
        [onChange, hide],
    );
    const showIcon = props.showIcon ?? true;

    const createReferenceContactMutation = useCreateReferenceContactMutation();
    const regionId = useRegionId();

    const createNewReferralContact = useCallback(async () => {
        setInNewRefferalWorkflow(true);
        const response = await createReferenceContactMutation.mutateAsync({
            regionId,
        });
        if (response?.createReferenceContact?.id) {
            const referenceContactId = response.createReferenceContact.id;
            setNewReferenceContactId(referenceContactId);
            onChange({
                identifier: Maybe.none(),
                business: Maybe.none(),
                contact: Maybe.fromValue(referenceContactId),
            });
        }
    }, [regionId, createReferenceContactMutation, onChange]);

    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        {showIcon ? (
                            <div className="w-7">
                                <Icon name="38_ReferralSource" />
                            </div>
                        ) : null}
                        <span className="text-lg text-gray-700">
                            {label ?? "Referral Source"}
                        </span>
                    </div>
                    <div className="space-x-2 flex items-center">
                        {isReferenceMapPopulated(value) ? (
                            <DeleteButton
                                backgroundColor="bg-white"
                                confirm={{
                                    message: "Remove referral source from this client?",
                                    title: "Clear referral source?",
                                    confirmLabel: "Yes, clear",
                                }}
                                onClick={clearReferralSource}
                            />
                        ) : null}
                        <SecondaryButton onClick={handleChoose}>Choose</SecondaryButton>
                    </div>
                </div>
                <ReferralSourcePickerValue value={value} clientId={clientId} />
            </div>
            <Panel
                isOpen={showModal}
                headerText={
                    inNewRefferalWorkflow
                        ? "New Referral Source"
                        : "Choose Referral Source"
                }
                type={PanelType.extraLarge}
                onDismiss={hide}>
                {inNewRefferalWorkflow ? (
                    <NewInlineReference
                        referenceContactId={newReferenceContactId}
                        dismiss={hide}
                    />
                ) : (
                    <div className="space-y-1 lg:space-y-2">
                        <div className="flex justify-end">
                            <PrimaryButton onClick={createNewReferralContact}>
                                Create new referral contact
                            </PrimaryButton>
                        </div>
                        <ReferralSourceSearch
                            onSelect={handleSearchSelect}
                            exclude={exclude}
                        />
                    </div>
                )}
            </Panel>
        </>
    );
};
