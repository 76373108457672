import { CommunityId } from "@sp-crm/core";
import { ListClient } from "components/clients/list-client";
import { Col, Row } from "components/shared/grid";
import { useGetReferralsToCommunityQuery } from "generated/graphql";
import React from "react";
import { useResponsiveMode, useUsers } from "store/selectors/hooks";

interface CommunityOutboundReferralsProps {
    communityId: CommunityId;
}

export const CommunityOutboundReferrals: React.FC<
    CommunityOutboundReferralsProps
> = props => {
    const { communityId } = props;

    const getReferralsQuery = useGetReferralsToCommunityQuery({
        communityId,
    });

    const directlyReferredClients = React.useMemo(() => {
        return (getReferralsQuery.data?.getReferralsToCommunity || [])
            .map(r => r.client)
            .filter(c => !!c);
    }, [getReferralsQuery.data]);

    const users = useUsers().users;
    const responsiveMode = useResponsiveMode();

    if (getReferralsQuery.data && directlyReferredClients.length === 0) {
        return <h3>No clients have been referred to this community.</h3>;
    }
    return (
        <div>
            <Row type="flex" className="collapsible">
                <Col className="full-width">
                    <div className="list-reference">
                        <ListClient
                            showAssignedToColumn={false}
                            users={users}
                            clients={directlyReferredClients}
                            tableShort={true}
                            responsiveMode={responsiveMode}
                            sourceType={"fromReferralBusiness"}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};
