import { CalendarDateTime } from "@sp-crm/core";
import * as React from "react";
import { Toggle } from "./toggle";

interface Props {
    label: string;
    value: CalendarDateTime | undefined | null;
    onChange?: (newValue: CalendarDateTime | null) => void;
}

export const CalendarDateTimeInput: React.FC<Props> = props => {
    const { label, value, onChange } = props;
    const mainInputRef = React.useRef<HTMLInputElement>(null);
    const isTimeEnabled = !!value?.hasTime;
    const [dateValueDisplay, setDateValueDisplay] = React.useState("");
    const [timeValueDisplay, setTimeValueDisplay] = React.useState("");
    React.useEffect(() => {
        setDateValueDisplay(value?.dateString ?? "");
        setTimeValueDisplay(value?.timeString ?? "");
    }, [setDateValueDisplay, setTimeValueDisplay, value]);
    const dateChangeHandler = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setDateValueDisplay(e.target.value);
        },
        [setDateValueDisplay],
    );
    const timeChangeHandler = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setTimeValueDisplay(e.target.value);
        },
        [setTimeValueDisplay],
    );
    const dateOnBlur = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!onChange) return;
            if (!value) {
                onChange(
                    CalendarDateTime.parse(e.target.value).getOrElse(
                        new CalendarDateTime(),
                    ),
                );
                return;
            }
            const [yyyy, mm, dd] = e.target.value.split("-");
            onChange(
                value.forDate(parseInt(yyyy, 10), parseInt(mm, 10), parseInt(dd, 10)),
            );
        },
        [value, onChange],
    );
    const timeOnBlur = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!onChange) return;
            const [hh, mm] = e.target.value.split(":").map(x => parseInt(x, 10));
            const newValue = value || new CalendarDateTime();
            onChange(e.target.value ? newValue.forTime(hh, mm) : newValue);
        },
        [value, onChange],
    );

    const toggleChangeHandler = React.useCallback(
        (newValue: boolean) => {
            const usedValue = value || new CalendarDateTime();
            if (!onChange) return;
            else if (newValue) {
                onChange(usedValue.withTime);
            } else {
                onChange(usedValue.withoutTime);
            }
        },
        [value, onChange],
    );
    const focusOnMainInput = React.useCallback(() => {
        mainInputRef.current?.focus();
    }, [mainInputRef]);
    return (
        <div className="w-full">
            {label ? (
                <label onClick={focusOnMainInput}>
                    <div className="mb-1">{label}</div>
                </label>
            ) : null}
            <div className="flex items-center space-x-1 lg:space-x-2">
                <div className="flex-1">
                    <input
                        onBlur={dateOnBlur}
                        ref={mainInputRef}
                        className="form-input rounded-sm w-full md:rounded disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                        type="date"
                        value={dateValueDisplay}
                        onChange={dateChangeHandler}
                    />
                </div>
                <div className="flex-1">
                    <div className="flex items-center space-x-1 lg:space-x-2">
                        <div>
                            <Toggle
                                label={isTimeEnabled ? null : "Set specific time"}
                                checked={isTimeEnabled}
                                onChange={toggleChangeHandler}
                            />
                        </div>
                        {isTimeEnabled ? (
                            <div className="flex-1">
                                <input
                                    onBlur={timeOnBlur}
                                    className="form-input rounded-sm w-full md:rounded disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                                    type="time"
                                    value={timeValueDisplay}
                                    onChange={timeChangeHandler}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
