import { CustomListItemId, CustomListKey } from "@sp-crm/core";
import React, { useMemo } from "react";
import Multiselect from "react-widgets/Multiselect";
import { useCustomList } from "store/selectors/bridge";

interface MultiselectItem {
    id: CustomListItemId;
    key: string;
    name: string;
    groupName: string;
}

interface CustomListMultiselectProps {
    customListKey: CustomListKey;
    value: string[] | null;
    onChange: (value: CustomListItemId[]) => void;
    disabled?: boolean;
}

export const CustomListMultiselect: React.FC<CustomListMultiselectProps> = props => {
    const { value, onChange, disabled } = props;

    const { customList, getListItem } = useCustomList(CustomListKey.ClientStatus);

    const handleChange = (selected: MultiselectItem[]) => {
        onChange(selected.map(s => s.id));
    };

    const multiSelectItems: MultiselectItem[] = useMemo(() => {
        const activeItems =
            customList?.items
                .filter(item => !item.isArchived)
                .map(item => ({
                    id: item.id,
                    key: item.key,
                    name: item.name,
                    groupName:
                        customList.categories.find(
                            c => c.id === item.customListCategoryId,
                        )?.name || "Uncategorized",
                })) || [];

        const archivedItems =
            customList?.items
                .filter(item => item.isArchived)
                .map(item => ({
                    id: item.id,
                    key: item.key,
                    name: item.name,
                    groupName: "Archived",
                })) || [];

        return [...activeItems, ...archivedItems];
    }, [customList]);

    const valueIds = useMemo(() => {
        if (!value) {
            return [];
        }

        const result: string[] = [];

        for (const idOrKey of value) {
            const item = getListItem(idOrKey);

            if (item) {
                result.push(item.id);
            }
        }

        return result;
    }, [value, getListItem]);

    if (multiSelectItems.length === 0) {
        return null;
    }

    return (
        <Multiselect
            disabled={disabled}
            data={multiSelectItems}
            value={valueIds}
            dataKey="id"
            textField="name"
            groupBy="groupName"
            onChange={handleChange}
        />
    );
};
