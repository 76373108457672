import {
    AnswerEntityId,
    defaultLiveLinkTextLabel,
    emailTokens,
    FileId,
    IContact,
} from "@sp-crm/core";
import { fancyAlert } from "components/ui/fancy-alert";
import { InlineBanner } from "components/ui/inline-banner";
import { defaultLinkStyle } from "components/ui/link";
import React, {
    forwardRef,
    ForwardRefExoticComponent,
    MutableRefObject,
    RefAttributes,
    useEffect,
    useImperativeHandle,
} from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useBridgeTokens } from "store/selectors/bridge";
import { usePreferences, useUserContacts } from "store/selectors/hooks";
import { breakNewlines } from "util/text";
import { handleEvent } from "util/user-events";
import {
    ComposeMessageQuery,
    OutboundMessageSpecialCommunityComparison,
    SendMessageMutationVariables,
    useComposeMessageQuery,
    useSendMessageMutation,
} from "../../generated/graphql";
import { Editor, PlacementEditor } from "../editor/editor";
import {
    buildInitialContentForSignature,
    composeInitialContent,
    Template,
} from "../editor/template";
import {
    getInsertableTemplates,
    getSignatureTemplates,
} from "../editor/upgrade-template";
import { Checkbox } from "../ui/checkbox";
import { ErrorMessage } from "../ui/error-message";
import { Input } from "../ui/input";
import { Panel } from "../ui/panel/panel";
import { PanelType } from "../ui/panel/panel-type";
import { PrimaryButton } from "../ui/primary-button";
import { SecondaryButton } from "../ui/secondary-button";
import { Spinner } from "../ui/spinner";
import { Attachments } from "./attachments";
import { RecipientsField } from "./recipients-field";
import { SpecialComposeCommmunityComparison } from "./special/community-comparison";
import { PlacementMessageCompose, Props } from "./types";

export const MessageCompose: React.FC<Props> = props => {
    const { entityId, requestSpecialMessageExperience } = props;

    const query = useComposeMessageQuery(
        {
            entityId,
            specialMessageExperience: requestSpecialMessageExperience,
        },
        {
            cacheTime: 0,
        },
    );

    const contents = () => {
        if (query.isLoading) {
            return <Spinner />;
        }
        if (query.isError) {
            return (
                <ErrorMessage component="InvoiceList">
                    <pre>{JSON.stringify(query.error, null, 2)}</pre>
                </ErrorMessage>
            );
        }
        if (!query.data) {
            return <Spinner />;
        }
        const templates = getInsertableTemplates(query.data.getTemplates.templates);
        const signatures = getSignatureTemplates(query.data.getTemplates.templates);
        return (
            <MessageComposeInternal
                {...props}
                {...query.data.composeMessage}
                signatures={signatures}
                templates={templates}
            />
        );
    };
    return (
        <Panel
            type={PanelType.extraLarge}
            hasCloseButton={false}
            isOpen={true}
            headerText="Compose">
            {contents()}
        </Panel>
    );
};

interface InternalExtraProps {
    signatures: Template[];
    templates: Template[];
    _debugCallback?: (str: string) => void;
    hideSendControls?: boolean;
    initialRecipients?: string[];
}
type InternalProps = Props & ComposeMessageQuery["composeMessage"] & InternalExtraProps;

export const MessageComposeInternal: ForwardRefExoticComponent<
    InternalProps & RefAttributes<PlacementMessageCompose>
> = forwardRef<PlacementMessageCompose, InternalProps>((props, ref) => {
    const {
        recipients: suggestedRecipients,
        onDismiss,
        signatures,
        templates,
        files,
        entityId,
        entityType,
        requestSpecialMessageExperience,
        specialCommunityComparisonDefaults,
        renderCommunityComparison,
        initialRecipients,
    } = props;
    const queryClient = useQueryClient();

    const userPreferences = usePreferences();

    const suggestedRecipientContacts: IContact[] = React.useMemo(
        () =>
            suggestedRecipients.map(
                r =>
                    ({
                        name: r,
                        email1: r,
                    } as IContact),
            ),
        [suggestedRecipients],
    );

    const initialRecipientContacts: IContact[] = React.useMemo(
        () =>
            initialRecipients?.map(
                r =>
                    ({
                        name: r,
                        email1: r,
                    } as IContact),
            ),
        [initialRecipients],
    );

    const userContacts = useUserContacts();

    const [recipients, setRecipients] = React.useState<IContact[]>(
        initialRecipientContacts ?? suggestedRecipientContacts,
    );

    const suggestedCcContacts: IContact[] = React.useMemo(() => {
        const candidates = [...suggestedRecipientContacts, ...userContacts];

        const unusedSuggestions = candidates.filter(
            c => !recipients.some(r => r.email1 === c.email1),
        );

        return unusedSuggestions;
    }, [suggestedRecipientContacts, recipients, userContacts]);

    const [status, setStatus] = React.useState<"idle" | "sending" | "error">("idle");
    const [rejected, setRejected] = React.useState<string[]>([]);
    const [code, setCode] = React.useState<string | null>(null);
    const [subject, setSubject] = React.useState("");
    const [hasLinkToken, setHasLinkToken] = React.useState(false);
    const [selectedAttachments, setSelectedAttachments] = React.useState<FileId[]>([]);
    const [ccRecipients, setCcRecipients] = React.useState<IContact[]>([]);
    const [showCc, setShowCc] = React.useState(false);
    const onSelectFile = React.useCallback((fileId: FileId) => {
        setSelectedAttachments(existingSelectedAttachments => {
            if (existingSelectedAttachments.includes(fileId)) {
                return existingSelectedAttachments.filter(id => id !== fileId);
            }
            return [...existingSelectedAttachments, fileId];
        });
    }, []);
    const [bccMyself, setBcc] = React.useState(userPreferences.bccMyself);
    const onRecipientsChange = React.useCallback(
        (contacts: IContact[]) => setRecipients(contacts),
        [],
    );
    const initialContent = React.useMemo(() => {
        const defaultSignature = signatures.find(s => s.isDefaultSignature);
        if (defaultSignature) return buildInitialContentForSignature(defaultSignature);
        return composeInitialContent;
    }, [signatures]);
    const editorRef =
        React.useRef<PlacementEditor>() as MutableRefObject<PlacementEditor>;
    const onSubjectChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setSubject(e.target.value),
        [setSubject],
    );
    const onBccChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setBcc(e.target.checked),
        [setBcc],
    );
    const sendMessageAction = useSendMessageMutation();
    const [specialCommunityComparisonValues, setSpecialCommunityComparisonValues] =
        React.useState<OutboundMessageSpecialCommunityComparison>({
            includeLiveLink: specialCommunityComparisonDefaults
                ? specialCommunityComparisonDefaults.useLiveLinkByDefault
                : true,
            includePDF: specialCommunityComparisonDefaults
                ? specialCommunityComparisonDefaults.attachCommunityPDFByDefault
                : true,
            showCommunityRecords: specialCommunityComparisonDefaults
                ? specialCommunityComparisonDefaults.showRecords
                : false,
            showFinancialDetails: true,
            showPrimaryContacts: true,
            showCoverPage: specialCommunityComparisonDefaults
                ? specialCommunityComparisonDefaults.showCoverPage
                : false,
            coverPageTemplate:
                templates.length === 1
                    ? templates[0].id
                    : specialCommunityComparisonDefaults
                    ? specialCommunityComparisonDefaults.coverPageTemplateId
                    : null,
        });

    useImperativeHandle(ref, () => {
        return {
            getMessage: () => {
                return {
                    bccMyself,
                    attachmentIds: selectedAttachments,
                    entityId,
                    recipients: recipients.map(r => r.email1),
                    subject,
                    body: editorRef.current.getContent(),
                    specialCommunityComparison:
                        requestSpecialMessageExperience === "community-comparison"
                            ? specialCommunityComparisonValues
                            : null,
                };
            },
        };
    });
    useEffect(() => {
        const checkLinkToken = () => {
            if (
                requestSpecialMessageExperience === "community-comparison" ||
                requestSpecialMessageExperience === "hosted-form"
            ) {
                return;
            }

            if (editorRef && editorRef.current && editorRef.current) {
                const contents = editorRef.current.getContent();
                if (contents && contents.includes(emailTokens.intakeLiveLink)) {
                    setHasLinkToken(true);
                } else {
                    setHasLinkToken(false);
                }
            } else {
                setHasLinkToken(false);
            }
        };
        const interval = setInterval(checkLinkToken, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [editorRef, setHasLinkToken]); // eslint-disable-line react-hooks/exhaustive-deps
    const sendMessage = () => {
        (async () => {
            const body = editorRef.current.getContent();
            if (
                specialCommunityComparisonValues.includeLiveLink &&
                requestSpecialMessageExperience === "community-comparison" &&
                !(body || "").includes(emailTokens.intakeLiveLink)
            ) {
                await fancyAlert(
                    "Link Placeholder Missing",
                    `To send an email with a community comparison link, the message body must contain ${emailTokens.intakeLiveLink}. To proceed, either add ${emailTokens.intakeLiveLink} to the body or uncheck the "Insert link" box.`,
                );
                return;
            }

            setStatus("sending");
            handleEvent("message-send", { source: "message-compose" });
            const message: SendMessageMutationVariables["message"] = {
                bccMyself,
                attachmentIds: selectedAttachments,
                entityId,
                recipients: recipients.map(r => r.email1),
                cc: ccRecipients.map(r => r.email1),
                subject,
                body,
                specialCommunityComparison:
                    requestSpecialMessageExperience === "community-comparison"
                        ? specialCommunityComparisonValues
                        : null,
            };
            const result = await sendMessageAction.mutateAsync({ message });
            if (
                result.sendMessage.status === "success" &&
                result.sendMessage.rejected.length <= 0
            ) {
                queryClient.invalidateQueries(["getMessagesForEntity", { id: entityId }]);
                onDismiss();
            } else {
                setStatus("error");
                setRejected(result.sendMessage.rejected);
                setCode(result.sendMessage.code);
            }
        })();
    };

    const handleTemplateInserted = React.useCallback(
        (template: Template) => {
            if (template?.subject && !subject) {
                setSubject(template.subject);
            }

            if (Array.isArray(template.attachments)) {
                setSelectedAttachments(prev => {
                    return [
                        ...prev,
                        ...template.attachments.filter(id => !prev.includes(id)),
                    ];
                });
            }
        },
        [subject, setSubject],
    );

    const handleCcClick = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            setShowCc(true);
        },
        [setShowCc],
    );

    const { tokenReplacements, addToken } = useBridgeTokens(
        entityType,
        entityId as AnswerEntityId,
    );

    return (
        <div className="space-y-4">
            <div className="space-y-2">
                <div>
                    <RecipientsField
                        label="To"
                        recipients={recipients}
                        onChange={onRecipientsChange}
                        suggestions={suggestedRecipientContacts}
                    />
                    {showCc ? (
                        <RecipientsField
                            label="Cc"
                            recipients={ccRecipients}
                            onChange={setCcRecipients}
                            suggestions={suggestedCcContacts}
                        />
                    ) : (
                        <div className="flex items-center justify-end">
                            <a
                                className={defaultLinkStyle}
                                href="#"
                                onClick={handleCcClick}>
                                Cc
                            </a>
                        </div>
                    )}
                </div>
                <Checkbox
                    label="BCC myself on this email"
                    checked={bccMyself}
                    onChange={onBccChange}
                />
            </div>
            <Input label="Subject" value={subject} onChange={onSubjectChange} />
            <div className="text-editor">
                <Editor
                    ref={editorRef}
                    templates={templates}
                    signatures={signatures}
                    initialContent={initialContent}
                    onTemplateInserted={handleTemplateInserted}
                    tokenReplacements={tokenReplacements}
                    onTokenInserted={addToken}
                    placeholderTypes={[entityType]}
                />
            </div>
            {hasLinkToken ? (
                <div className="bg-yellow-100 text-yellow-600 rounded-sm font-bold p-4">
                    Your email includes <code>{emailTokens.intakeLiveLink}</code> which is
                    only supported in a <em>Community Comparison</em> email.
                </div>
            ) : null}
            {requestSpecialMessageExperience === "community-comparison" &&
            specialCommunityComparisonValues ? (
                renderCommunityComparison ? (
                    renderCommunityComparison({
                        liveLinkText: specialCommunityComparisonDefaults
                            ? specialCommunityComparisonDefaults.liveLinkText
                            : defaultLiveLinkTextLabel,
                        values: specialCommunityComparisonValues,
                        onChange: setSpecialCommunityComparisonValues,
                        templates,
                    })
                ) : (
                    <SpecialComposeCommmunityComparison
                        liveLinkText={
                            specialCommunityComparisonDefaults
                                ? specialCommunityComparisonDefaults.liveLinkText
                                : defaultLiveLinkTextLabel
                        }
                        values={specialCommunityComparisonValues}
                        onChange={setSpecialCommunityComparisonValues}
                        templates={templates}
                    />
                )
            ) : null}

            <div className="flex justify-between items-start">
                <div>
                    <Attachments
                        scopes={files}
                        selectedFileIds={selectedAttachments}
                        onSelectFile={onSelectFile}
                    />
                </div>
                {props.hideSendControls ? null : (
                    <div className="flex items-center justify-end space-x-3">
                        {status === "idle" ? (
                            <>
                                <SecondaryButton onClick={onDismiss}>
                                    Discard
                                </SecondaryButton>
                                <PrimaryButton onClick={sendMessage}>Send</PrimaryButton>
                            </>
                        ) : null}
                        {status === "sending" ? <Spinner /> : null}
                    </div>
                )}
            </div>

            {code === "EAUTH" ? (
                <InlineBanner type="error" showIcon={false}>
                    Sending this email failed because the configured username or password
                    for your email provider needs to be updated. Navigate to your{" "}
                    <Link className={defaultLinkStyle} to="/settings/account/email">
                        email settings page
                    </Link>
                    , reconnect your email provider, and try again.
                </InlineBanner>
            ) : rejected.length > 0 ? (
                <InlineBanner type="error" showIcon={false}>
                    Sending this email to the recipients below failed, likely due to a
                    formatting issue with the email address. Refresh the page, update
                    those email addresses, and try again.
                    <br />
                    <br />
                    {breakNewlines(rejected.join("\n"))}
                </InlineBanner>
            ) : null}
            {rejected.length === 0 && status === "error" ? (
                <div className="bg-red-100 text-red-500 font-bold text-2xl p-4">
                    Sorry, there was an error sending your message.
                </div>
            ) : null}
        </div>
    );
});

MessageComposeInternal.displayName = "MessageComposeInternal";
