import { IQuestion, LayoutItemId, findRelatedField } from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import { BridgeEntityResult } from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { LayoutItemResult } from "./layout-items";

interface LayoutItemCustomTitleProps {
    entityMetadata: BridgeEntityResult;
    entityMetadataList: BridgeEntityResult[];
    questions: IQuestion[];
    layoutItem: LayoutItemResult;
    onTitleChange: (id: LayoutItemId, newTitle: string | null) => void;
    disabled?: boolean;
}

export const LayoutItemCustomTitle: React.FC<LayoutItemCustomTitleProps> = props => {
    const {
        layoutItem,
        onTitleChange,
        entityMetadata,
        entityMetadataList,
        questions,
        disabled,
    } = props;

    const handleTitleUpdate = useCallback(
        (newTitle: string) => {
            onTitleChange(layoutItem.id, newTitle);
        },
        [onTitleChange, layoutItem.id],
    );

    const workingTitle = useMemo(() => {
        if (layoutItem.customTitle) {
            return layoutItem.customTitle;
        }

        if (layoutItem.questionId) {
            const title =
                questions.find(q => q.id === layoutItem.questionId)?.title ||
                layoutItem.questionId;

            return title;
        }

        if (layoutItem.entityFieldName) {
            const relatedField = findRelatedField(
                layoutItem.entityFieldName,
                entityMetadata,
                entityMetadataList,
            );

            return relatedField.metadata.title;
        }

        return "";
    }, [layoutItem, entityMetadata, entityMetadataList, questions]);

    return (
        <div className="flex items-center space-x-2 text-sm">
            <p className="w-16">Show as</p>
            <AutosavingInput
                initial={workingTitle}
                onCommit={handleTitleUpdate}
                disabled={disabled}
            />
        </div>
    );
};
