import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import {
    AnswerEntityId,
    FileEntityId,
    FileId,
    IContact,
    SignatureRequestId,
    SignatureTemplateFieldId,
    formatSignatureFieldValue,
    getSignatureCheckboxFieldSymbol,
    parseEntityId,
    validEmail,
} from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentSection2Up, SectionHeader, Stage } from "components/layout";
import { ReadOnlyAttachments } from "components/manage/templates/read-only-attachments";
import { RecipientsField } from "components/messages/recipients-field";
import { useQueryParams } from "components/shared/hooks";
import { Checkbox } from "components/ui/checkbox";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { Radio } from "components/ui/radio";
import { SecondaryButton } from "components/ui/secondary-button";
import { Select } from "components/ui/select";
import { Spinner } from "components/ui/spinner";
import {
    AdvancedSearchEntityType,
    CreateSignatureRequestMutation,
    CreateSignatureRequestMutationVariables,
    DraftSignatureRequestEmailContent,
    GetDraftSignatureRequestQuery,
    GetSignatureTemplateQuery,
    SignatureFieldType,
    SignatureRequestCcRecipient,
    SignatureRequestFieldPayload,
    SignatureTemplateFieldInput,
    SignatureTemplateInput,
    ValidateTemplateQuery,
    useCreateSignatureRequestMutation,
    useGetDraftSignatureRequestQuery,
} from "generated/graphql";
import { produce } from "immer";
import React, { useEffect, useMemo, useState } from "react";
import { DocumentCallback, PageCallback } from "react-pdf/dist/cjs/shared/types";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Actions } from "store/actions";
import { GlobalSuccessNotificationCreate } from "store/actions/global-notifications";
import { useBridgeTokens } from "store/selectors/bridge";
import { getSanitizedMarkUpFromEmailBody } from "util/email";
import {
    DocumentPdfInfo,
    DocumentRender,
    PdfDocumentProxyMetadata,
} from "../pdf/document-render";
import {
    compareSignatureTemplateFields,
    validateSignatureRequestBody,
} from "../signature-defaults";
import {
    getInlineSignatureBackgroundStyle,
    getSignExperienceBackgroundStyle,
    getSignatureBackgroundStyle,
    getSignatureBorderStyle,
    getSignatureLinkMetadataStyle,
    getSignatureLinkStyle,
    getSignaturePlaceholderStyle,
    getSignatureRingStyle,
} from "../signature-template/signature-colors";
import { SignatureCustomizeEmailDialog } from "../signature-template/signature-customize-email-dialog";
import { SignatureFieldIcon } from "../signature-template/signature-field-icon";

interface Recipient {
    name: string;
    email: string;
}

interface State {
    signatureTemplate: SignatureTemplateInput;
    finishedInitialLoad: boolean;
    recipients: Recipient[];
    ccRecipients: SignatureRequestCcRecipient[];
    isLoading: boolean;
    mutationResponse?: CreateSignatureRequestMutation["createSignatureRequest"];
    numPages: number | null;
    pageNumber: number | null;
    currentPageDimensions: number[] | null;
    selectedTemplateFieldId: SignatureTemplateFieldId | null;
    potentialRecipients:
        | GetDraftSignatureRequestQuery["getDraftSignatureRequest"]["potentialRecipients"]
        | null;
    errorBanner: string | null;
    templateEmailContent: DraftSignatureRequestEmailContent | null;
    showCustomizeEmailDialog: boolean;
    customEmailContent: {
        subject?: string | null;
        body?: string | null;
        attachments?: FileId[] | null;
    } | null;
    pdfInfo: DocumentPdfInfo | null;
    entityType: AdvancedSearchEntityType | null;
}

const initialState: State = {
    signatureTemplate: {
        signatureTemplateFields: [],
        recipientCount: 0,
    },
    recipients: [],
    ccRecipients: [],
    finishedInitialLoad: false,
    isLoading: false,
    numPages: null,
    pageNumber: null,
    currentPageDimensions: null,
    selectedTemplateFieldId: null,
    potentialRecipients: null,
    errorBanner: null,
    templateEmailContent: null,
    showCustomizeEmailDialog: false,
    customEmailContent: null,
    pdfInfo: null,
    entityType: null,
};

type Action =
    | { type: "setPage"; page: number }
    | {
          type: "onDocumentLoadSuccess";
          document: DocumentCallback;
          documentMetadata: PdfDocumentProxyMetadata;
      }
    | { type: "onPageLoadSuccess"; document: PageCallback }
    | { type: "updateField"; field: SignatureTemplateFieldInput }
    | { type: "selectField"; fieldId: SignatureTemplateFieldId }
    | { type: "deselectField" }
    | { type: "updateRecipientName"; index: number; value: string }
    | { type: "updateFieldTextValue"; fieldId: SignatureTemplateFieldId; value: string }
    | {
          type: "updateFieldBooleanValue";
          fieldId: SignatureTemplateFieldId;
          value: boolean;
      }
    | { type: "updateRecipientEmail"; index: number; value: string }
    | { type: "createRequest" }
    | {
          type: "createRequestComplete";
          payload: CreateSignatureRequestMutation["createSignatureRequest"];
      }
    | {
          type: "createRequestError";
          error: Error;
      }
    | {
          type: "initialLoadComplete";
          payload: GetDraftSignatureRequestQuery["getDraftSignatureRequest"];
      }
    | {
          type: "emailTemplateValidationComplete";
          result: ValidateTemplateQuery["validateTemplate"];
      }
    | {
          type: "showCustomizeEmailDialog";
      }
    | {
          type: "hideCustomizeEmailDialog";
      }
    | {
          type: "updateCustomEmailContent";
          payload: {
              subject?: string | null;
              body?: string | null;
              attachments?: FileId[] | null;
          } | null;
      }
    | {
          type: "setCcRecipients";
          recipients: IContact[];
      }
    | {
          type: "updateFieldIsRequired";
          fieldId: SignatureTemplateFieldId;
          value: boolean | null;
      };

const filledArray = (length: number): number[] => Array.from({ length }, (_, i) => i + 1);

const reducer = (state: State, action: Action): State => {
    return produce(state, draft => {
        if (action.type === "setPage") {
            draft.pageNumber = action.page;
        }

        if (action.type === "onDocumentLoadSuccess") {
            draft.numPages = action.document.numPages;
            draft.pageNumber = 1;
            draft.pdfInfo = action.documentMetadata.info;
        }
        if (
            action.type === "initialLoadComplete" &&
            !draft.finishedInitialLoad &&
            action.payload
        ) {
            draft.signatureTemplate = action.payload.signatureTemplate;
            draft.recipients = filledArray(
                action.payload.signatureTemplate.recipientCount,
            ).map(x => ({
                name: "",
                email: "",
            }));
            if (action.payload.actualRecipients) {
                action.payload.actualRecipients.forEach((recipient, index) => {
                    draft.recipients[index].name = recipient.name;
                    draft.recipients[index].email = recipient.email;
                });
            }
            draft.potentialRecipients = action.payload.potentialRecipients;
            if (action.payload.draftFields) {
                action.payload.draftFields.forEach(field => {
                    const existingField =
                        draft.signatureTemplate.signatureTemplateFields.find(
                            f => f.id === field.signatureTemplateFieldId,
                        );
                    if (existingField) {
                        existingField.defaultTextValue = field.textValue;
                        existingField.defaultBooleanValue = field.booleanValue;
                        existingField.placeholder = field.placeholder;
                        existingField.isRequired = field.isRequired;
                    }
                });
            }
            if (action.payload.ccRecipients) {
                draft.ccRecipients = action.payload.ccRecipients;
            }
            draft.templateEmailContent = action.payload.emailTemplate;
            draft.finishedInitialLoad = true;
            draft.entityType = action.payload.entityType;
        }
        if (action.type === "updateRecipientName") {
            draft.recipients[action.index]["name"] = action.value;
        }
        if (action.type === "updateRecipientEmail") {
            draft.recipients[action.index]["email"] = action.value;
        }
        if (action.type === "createRequest") {
            draft.isLoading = true;
            draft.errorBanner = null;
        }
        if (action.type === "createRequestComplete") {
            draft.isLoading = false;
            draft.mutationResponse = action.payload;
        }
        if (action.type === "createRequestError") {
            draft.isLoading = false;
            draft.errorBanner = `The signature request did not send due to this error: ${action.error.message}. After resolving the error, please try again`;
        }
        if (action.type === "selectField") {
            const field = draft.signatureTemplate.signatureTemplateFields.find(
                f => f.id === action.fieldId,
            );
            if (field) {
                draft.selectedTemplateFieldId = action.fieldId;
                draft.pageNumber = field.page;
            }
        }
        if (action.type === "deselectField") {
            draft.selectedTemplateFieldId = null;
        }
        if (action.type === "updateFieldTextValue") {
            const field = draft.signatureTemplate.signatureTemplateFields.find(
                x => x.id === action.fieldId,
            );
            if (field) {
                field.defaultTextValue = action.value;
                field.defaultBooleanValue = null;
            }
        }
        if (action.type === "updateFieldBooleanValue") {
            const field = draft.signatureTemplate.signatureTemplateFields.find(
                x => x.id === action.fieldId,
            );
            if (field) {
                field.defaultBooleanValue = action.value;
                field.defaultTextValue = null;
            }
        }
        if (action.type === "showCustomizeEmailDialog") {
            draft.showCustomizeEmailDialog = true;
        }

        if (action.type === "hideCustomizeEmailDialog") {
            draft.showCustomizeEmailDialog = false;
        }

        if (action.type === "updateCustomEmailContent") {
            draft.customEmailContent = action.payload;
        }

        if (action.type === "setCcRecipients") {
            draft.ccRecipients = action.recipients.map(r => ({
                name: r.name,
                email: r.email1,
            }));
        }
        if (action.type === "updateFieldIsRequired") {
            const field = draft.signatureTemplate.signatureTemplateFields.find(
                f => f.id === action.fieldId,
            );
            if (field) {
                field.isRequired = action.value;
            }
        }
    });
};

export const SignatureRequestNew: React.FC<unknown> = () => {
    const { fileId, entityId } = useRouteMatch().params as {
        fileId: FileId;
        entityId: FileEntityId;
    };
    const params = useQueryParams();
    const signatureRequestId: SignatureRequestId | null = params.sourceRequestId
        ? parseEntityId(params.sourceRequestId)
        : null;
    const router = useHistory();
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const globalDispatch = useDispatch();
    const mutation = useCreateSignatureRequestMutation();
    const query = useGetDraftSignatureRequestQuery(
        { entityId, fileId, signatureRequestId },
        {
            onSuccess: data => {
                dispatch({
                    type: "initialLoadComplete",
                    payload: data.getDraftSignatureRequest,
                });
            },
        },
    );

    const send = async () => {
        dispatch({ type: "createRequest" });
        const fields: SignatureRequestFieldPayload[] = [];
        if (state?.signatureTemplate?.signatureTemplateFields) {
            for (const field of state.signatureTemplate.signatureTemplateFields) {
                fields.push({
                    signatureTemplateFieldId: field.id,
                    textValue: field.defaultTextValue,
                    booleanValue: field.defaultBooleanValue,
                    placeholder: field.placeholder,
                    isRequired: field.isRequired,
                });
            }
        }
        const payload: CreateSignatureRequestMutationVariables = {
            signatureRequestPayload: {
                fileEntityId: entityId,
                fileId,
                recipients: state.recipients,
                fields,
                emailTemplate: state.customEmailContent,
                ccRecipients: state.ccRecipients,
            },
        };
        try {
            const response = await mutation.mutateAsync(payload);

            dispatch({
                type: "createRequestComplete",
                payload: response.createSignatureRequest,
            });
            const successNotification: GlobalSuccessNotificationCreate = {
                messageId: "signature-request-created",
                messageTitle: "Sent!",
                messageBody: "Signature request sent successfully.",
                type: Actions[Actions.GLOBAL_SUCCESS_NOTIFY],
            };
            globalDispatch(successNotification);
            router.goBack();
        } catch (e) {
            dispatch({
                type: "createRequestError",
                error: e,
            });
        }
    };
    const onLoadSuccess = React.useCallback(async (document: DocumentCallback) => {
        const documentMetadata = await document.getMetadata();
        dispatch({ type: "onDocumentLoadSuccess", document, documentMetadata });
    }, []);
    const onPageRender = React.useCallback((document: PageCallback) => {
        dispatch({ type: "onPageLoadSuccess", document });
    }, []);
    const setPage = React.useCallback((pageNumber: number) => {
        dispatch({ type: "setPage", page: pageNumber });
    }, []);
    const showCustomizeEmailDialog = React.useCallback(() => {
        dispatch({ type: "showCustomizeEmailDialog" });
    }, []);
    const hideCustomizeEmailDialog = React.useCallback(() => {
        dispatch({ type: "hideCustomizeEmailDialog" });
    }, []);
    const handleCcRecipientsChange = React.useCallback((recipients: IContact[]) => {
        dispatch({ type: "setCcRecipients", recipients });
    }, []);
    const recipient0InputRefs = React.useRef(
        {} as Record<SignatureTemplateFieldId, HTMLInputElement>,
    );

    useEffect(() => {
        if (state.selectedTemplateFieldId) {
            recipient0InputRefs.current[state.selectedTemplateFieldId]?.focus();
        }
    }, [state.selectedTemplateFieldId]);

    const fieldsForPage = state.signatureTemplate.signatureTemplateFields
        .filter(f => f.page === state.pageNumber)
        .sort(compareSignatureTemplateFields);

    const fieldsForRecipient0 = state.signatureTemplate.signatureTemplateFields
        .filter(f => f.recipientIndex === 0)
        .sort(compareSignatureTemplateFields);

    const emailBodyError = useMemo(() => {
        if (!state.templateEmailContent?.body) {
            return null;
        }

        const response = validateSignatureRequestBody(
            state.customEmailContent?.body || state.templateEmailContent.body,
        );

        if (response.isValid) {
            return null;
        }

        return response.errorMessage;
    }, [state.customEmailContent?.body, state.templateEmailContent?.body]);

    const ccRecipientsContacts: IContact[] = useMemo(() => {
        return state.ccRecipients.map(
            r =>
                ({
                    name: r.name,
                    email1: r.email,
                } as IContact),
        );
    }, [state.ccRecipients]);

    const ccRecipientsSuggestions: IContact[] = useMemo(() => {
        return (
            state.potentialRecipients?.map(
                r =>
                    ({
                        name: r.name,
                        email1: r.email,
                    } as IContact),
            ) || []
        ).filter(r => !state.recipients.find(x => x.email === r.email1));
    }, [state.potentialRecipients, state.recipients]);

    const { tokenReplacements, addToken } = useBridgeTokens(
        state.entityType,
        entityId as AnswerEntityId,
    );

    const showEncryptionBanner = typeof state.pdfInfo?.EncryptFilterName === "string";
    const canSend =
        !emailBodyError &&
        state.recipients.every(r => r.name && validEmail(r.email)) &&
        !showEncryptionBanner;

    return (
        <QueryRenderer query={query} name="SignatureRequestNew.GetSignatureTemplate">
            {data => (
                <Stage>
                    {state.errorBanner ? (
                        <InlineBanner type="error">{state.errorBanner}</InlineBanner>
                    ) : null}
                    {emailBodyError ? (
                        <InlineBanner type="warning">{emailBodyError}</InlineBanner>
                    ) : null}
                    {showEncryptionBanner ? (
                        <InlineBanner type="warning">
                            This SignWise template was configured with a file that has
                            password-protected content. Password-protected content is not
                            supported in SignWise. Please re-create the Signature Template
                            with a file without password protection and send with that
                            template.
                        </InlineBanner>
                    ) : null}
                    <SectionHeader
                        title={`SignWise - ${
                            data.getFile.originalFilename ?? "(untitled)"
                        } - New Signature Request`}
                    />
                    <ContentSection2Up>
                        <div className="flex space-x-1 md:space-x-4">
                            <div className="space-y-8 min-w-80 max-w-md px-2">
                                <ul className="space-y-4">
                                    {fieldsForRecipient0.length > 0 ? (
                                        <li className={getSignatureBackgroundStyle(0)}>
                                            <div className="font-bold">
                                                Confirm sender fields
                                            </div>
                                            <p className="text-sm">
                                                Set and confirm the sender fields below.
                                                These values will be added to the document
                                                that is sent to each recipient.
                                            </p>
                                            <ul className="space-y-2">
                                                {fieldsForRecipient0.map(field => (
                                                    <li
                                                        className="flex items-center space-x-2"
                                                        key={field.id}>
                                                        <SignatureFieldIcon
                                                            fieldType={field.fieldType}
                                                            recipientIndex={0}
                                                        />
                                                        {field.fieldType ===
                                                        SignatureFieldType.Checkbox ? (
                                                            <Checkbox
                                                                ref={el =>
                                                                    (recipient0InputRefs.current[
                                                                        field.id
                                                                    ] = el)
                                                                }
                                                                className="flex items-center"
                                                                checked={
                                                                    field.defaultBooleanValue
                                                                }
                                                                onChange={e =>
                                                                    dispatch({
                                                                        type: "updateFieldBooleanValue",
                                                                        fieldId: field.id,
                                                                        value: e.target
                                                                            .checked,
                                                                    })
                                                                }
                                                                onFocus={() =>
                                                                    dispatch({
                                                                        type: "selectField",
                                                                        fieldId: field.id,
                                                                    })
                                                                }
                                                                onBlur={() =>
                                                                    dispatch({
                                                                        type: "deselectField",
                                                                    })
                                                                }
                                                            />
                                                        ) : (
                                                            <Input
                                                                ref={el =>
                                                                    (recipient0InputRefs.current[
                                                                        field.id
                                                                    ] = el)
                                                                }
                                                                type={
                                                                    field.fieldType ===
                                                                    SignatureFieldType.Date
                                                                        ? "date"
                                                                        : "text"
                                                                }
                                                                className="flex-1"
                                                                onFocus={() =>
                                                                    dispatch({
                                                                        type: "selectField",
                                                                        fieldId: field.id,
                                                                    })
                                                                }
                                                                onBlur={() =>
                                                                    dispatch({
                                                                        type: "deselectField",
                                                                    })
                                                                }
                                                                value={
                                                                    field.defaultTextValue
                                                                }
                                                                onChange={e =>
                                                                    dispatch({
                                                                        type: "updateFieldTextValue",
                                                                        fieldId: field.id,
                                                                        value: e.target
                                                                            .value,
                                                                    })
                                                                }
                                                            />
                                                        )}
                                                        {state.numPages >= 2 ? (
                                                            <p
                                                                className={`text-sm ${getSignatureLinkMetadataStyle(
                                                                    0,
                                                                )}`}>
                                                                Page {field.page}
                                                            </p>
                                                        ) : null}
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ) : null}
                                    <li>
                                        <div className="bg-white md:rounded-sm lg:rounded lg:shadow">
                                            <div className="space-y-4">
                                                <div className="p-2 lg:p-4 space-y-4">
                                                    <div className="font-bold">
                                                        Set signing recipients
                                                    </div>
                                                    <p className="text-sm">
                                                        Confirm recipient names and
                                                        emails. The SignWise request will
                                                        be sent one-by-one, in the order
                                                        listed.
                                                    </p>
                                                </div>
                                                {Array.isArray(
                                                    state.potentialRecipients,
                                                ) ? (
                                                    <ul className="space-y-4">
                                                        {state.recipients.map(
                                                            (recipient, index) => {
                                                                const recipientFields =
                                                                    state.signatureTemplate.signatureTemplateFields
                                                                        .filter(
                                                                            f =>
                                                                                f.recipientIndex ===
                                                                                index + 1,
                                                                        )
                                                                        .sort(
                                                                            compareSignatureTemplateFields,
                                                                        );

                                                                return (
                                                                    <RecipientBox
                                                                        recipient={
                                                                            recipient
                                                                        }
                                                                        index={index}
                                                                        key={index}
                                                                        onEmailChange={email => {
                                                                            dispatch({
                                                                                type: "updateRecipientEmail",
                                                                                index,
                                                                                value: email,
                                                                            });
                                                                        }}
                                                                        onNameChange={name => {
                                                                            dispatch({
                                                                                type: "updateRecipientName",
                                                                                index,
                                                                                value: name,
                                                                            });
                                                                        }}
                                                                        potentialRecipients={
                                                                            state.potentialRecipients
                                                                        }
                                                                        onSelectField={fieldId => {
                                                                            dispatch({
                                                                                type: "selectField",
                                                                                fieldId,
                                                                            });
                                                                        }}
                                                                        onIsRequiredChange={(
                                                                            fieldId,
                                                                            value,
                                                                        ) => {
                                                                            dispatch({
                                                                                type: "updateFieldIsRequired",
                                                                                fieldId,
                                                                                value,
                                                                            });
                                                                        }}
                                                                        recipientFields={
                                                                            recipientFields
                                                                        }
                                                                        showPage={
                                                                            state.numPages >=
                                                                            2
                                                                        }
                                                                    />
                                                                );
                                                            },
                                                        )}
                                                    </ul>
                                                ) : null}
                                            </div>
                                        </div>
                                    </li>
                                    {Array.isArray(state.potentialRecipients) ? (
                                        <li>
                                            <div className="bg-white md:rounded-sm lg:rounded lg:shadow">
                                                <div className="space-y-4">
                                                    <div className="p-2 lg:p-4 space-y-4">
                                                        <div className="font-bold">
                                                            Set CC recipients
                                                        </div>
                                                        <p className="text-sm">
                                                            Add additional recipients to
                                                            receive a copy of the final
                                                            signed document.
                                                        </p>
                                                        <RecipientsField
                                                            label=""
                                                            suggestions={
                                                                ccRecipientsSuggestions
                                                            }
                                                            recipients={
                                                                ccRecipientsContacts
                                                            }
                                                            onChange={
                                                                handleCcRecipientsChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ) : null}
                                    {state.templateEmailContent ? (
                                        <li>
                                            <div className="bg-white md:rounded-sm lg:rounded lg:shadow">
                                                <div className="p-2 lg:p-4 space-y-4">
                                                    <div className="flex items-center justify-between">
                                                        <div className="font-bold">
                                                            Email content
                                                        </div>
                                                        <SecondaryButton
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                showCustomizeEmailDialog();
                                                            }}>
                                                            <span className="text-sm">
                                                                Customize
                                                            </span>
                                                        </SecondaryButton>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <p className="text-sm">Subject</p>
                                                        <p>
                                                            {state.customEmailContent
                                                                ?.subject ||
                                                                state.templateEmailContent
                                                                    .subject}
                                                        </p>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <p className="text-sm">Body</p>
                                                        <p
                                                            className="preview-email-body-content selectable-texts line-clamp-6"
                                                            dangerouslySetInnerHTML={getSanitizedMarkUpFromEmailBody(
                                                                state.customEmailContent
                                                                    ?.body ||
                                                                    state
                                                                        .templateEmailContent
                                                                        .body,
                                                            )}
                                                        />
                                                    </div>
                                                    <ReadOnlyAttachments
                                                        attachmentIds={
                                                            state.customEmailContent
                                                                ?.attachments ||
                                                            state.templateEmailContent
                                                                .attachments
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    ) : null}
                                </ul>

                                {state.isLoading ? (
                                    <SecondaryButton>
                                        <Spinner />
                                    </SecondaryButton>
                                ) : (
                                    <PrimaryButton disabled={!canSend} onClick={send}>
                                        Send
                                    </PrimaryButton>
                                )}
                            </div>
                            <DocumentRender
                                documentUrl={`/api/files/download/${fileId}`}
                                pageNumber={state.pageNumber}
                                setPageNumber={setPage}
                                onRenderSuccess={onPageRender}
                                extraControls={
                                    <div>
                                        {state.isLoading ? (
                                            <SecondaryButton>
                                                <Spinner />
                                            </SecondaryButton>
                                        ) : (
                                            <div>
                                                <PrimaryButton
                                                    disabled={!canSend}
                                                    onClick={send}>
                                                    Send
                                                </PrimaryButton>
                                            </div>
                                        )}
                                    </div>
                                }
                                onLoadSuccess={onLoadSuccess}>
                                {fieldsForPage.map(f =>
                                    f.recipientIndex === 0 ? (
                                        <SignExperienceUserField
                                            onSelectField={() =>
                                                dispatch({
                                                    type: "selectField",
                                                    fieldId: f.id,
                                                })
                                            }
                                            signatureTemplateField={f}
                                            isHighlighted={
                                                f.id === state.selectedTemplateFieldId
                                            }
                                            key={f.id}
                                        />
                                    ) : (
                                        <SignExperiencePlaceholderField
                                            signatureTemplateField={f}
                                            key={f.id}
                                            isHighlighted={
                                                f.id === state.selectedTemplateFieldId
                                            }
                                            onSelectField={() =>
                                                dispatch({
                                                    type: "selectField",
                                                    fieldId: f.id,
                                                })
                                            }
                                        />
                                    ),
                                )}
                            </DocumentRender>
                        </div>
                    </ContentSection2Up>
                    {state.templateEmailContent ? (
                        <SignatureCustomizeEmailDialog
                            isOpen={state.showCustomizeEmailDialog}
                            onDismiss={hideCustomizeEmailDialog}
                            defaultContent={state.templateEmailContent}
                            initialCustomContent={state.customEmailContent}
                            onCommit={newContent => {
                                dispatch({
                                    type: "updateCustomEmailContent",
                                    payload: newContent,
                                });
                            }}
                            defaultContentLabel="Use signature template email content"
                            defaultContentDescription="Use the email content defined in the signature template for this signature request"
                            placeholderTypes={
                                state.entityType ? [state.entityType] : null
                            }
                            tokenReplacements={tokenReplacements}
                            onTokenInserted={addToken}
                        />
                    ) : null}
                </Stage>
            )}
        </QueryRenderer>
    );
};

interface SignExperienceUserFieldProps {
    signatureTemplateField: GetSignatureTemplateQuery["getSignatureTemplate"]["signatureTemplateFields"][0];
    isHighlighted: boolean;
    onSelectField: () => void;
}

export const SignExperienceUserField: React.FC<SignExperienceUserFieldProps> = props => {
    const { signatureTemplateField, isHighlighted, onSelectField } = props;
    const fieldStyles: React.CSSProperties = {
        left: signatureTemplateField.x,
        top: signatureTemplateField.y,
        width: signatureTemplateField.width,
        height: signatureTemplateField.height,
        fontFamily: signatureTemplateField.font,
        fontSize: signatureTemplateField.fontSize,
        lineHeight: `${signatureTemplateField.height - 1}px`,
    };
    const fieldClasses = `absolute rounded-sm border-2 ${getSignaturePlaceholderStyle(
        signatureTemplateField.recipientIndex,
    )} ${getSignatureBorderStyle(signatureTemplateField.recipientIndex)} ${
        isHighlighted ? getSignatureRingStyle(signatureTemplateField.recipientIndex) : ""
    } ${
        signatureTemplateField.fieldType === SignatureFieldType.Checkbox
            ? "text-center"
            : ""
    }`;
    const arrowStyles: React.CSSProperties = {
        left: signatureTemplateField.x + signatureTemplateField.width + 8,
        top: signatureTemplateField.y + signatureTemplateField.height / 2 - 14,
    };
    const arrowClasses = `absolute rounded-full p-1 w-8 h-8 text-white ${getSignExperienceBackgroundStyle(
        signatureTemplateField.recipientIndex,
    )}`;
    const formattedValue = formatSignatureFieldValue({
        fieldType: signatureTemplateField.fieldType,
        textValue: signatureTemplateField.defaultTextValue,
        booleanValue: signatureTemplateField.defaultBooleanValue,
    });
    return (
        <>
            <div onClick={onSelectField} className={fieldClasses} style={fieldStyles}>
                {formattedValue}
            </div>
            {isHighlighted ? (
                <ArrowLeftIcon style={arrowStyles} className={arrowClasses} />
            ) : null}
        </>
    );
};

interface SignExperiencePlaceholderFieldProps {
    signatureTemplateField: GetSignatureTemplateQuery["getSignatureTemplate"]["signatureTemplateFields"][0];
    isHighlighted: boolean;
    onSelectField: () => void;
}

export const SignExperiencePlaceholderField: React.FC<
    SignExperiencePlaceholderFieldProps
> = props => {
    const { signatureTemplateField, onSelectField, isHighlighted } = props;
    const fieldStyles: React.CSSProperties = {
        left: signatureTemplateField.x,
        top: signatureTemplateField.y,
        width: signatureTemplateField.width,
        height: signatureTemplateField.height,
        fontFamily: signatureTemplateField.font,
        fontSize: signatureTemplateField.fontSize,
        lineHeight: `${signatureTemplateField.height - 1}px`,
    };
    const fieldClasses = `absolute rounded-sm p-1 border-2 flex items-center space-x-2 ${getSignaturePlaceholderStyle(
        signatureTemplateField.recipientIndex,
    )} ${getSignatureBorderStyle(signatureTemplateField.recipientIndex)} ${
        isHighlighted ? getSignatureRingStyle(signatureTemplateField.recipientIndex) : ""
    } ${
        signatureTemplateField.fieldType === SignatureFieldType.Checkbox
            ? "justify-center"
            : ""
    }`;
    const arrowStyles: React.CSSProperties = {
        left: signatureTemplateField.x + signatureTemplateField.width + 8,
        top: signatureTemplateField.y + signatureTemplateField.height / 2 - 14,
    };
    const arrowClasses = `absolute rounded-full p-1 w-8 h-8 text-white ${getSignExperienceBackgroundStyle(
        signatureTemplateField.recipientIndex,
    )}`;

    const placeholder =
        signatureTemplateField.fieldType === SignatureFieldType.Checkbox
            ? getSignatureCheckboxFieldSymbol(signatureTemplateField.defaultBooleanValue)
            : signatureTemplateField.defaultTextValue ||
              signatureTemplateField.placeholder;

    return (
        <>
            <div onClick={onSelectField} className={fieldClasses} style={fieldStyles}>
                {placeholder ? (
                    <p>{placeholder}</p>
                ) : (
                    <>
                        <SignatureFieldIcon
                            fieldType={signatureTemplateField.fieldType}
                            recipientIndex={signatureTemplateField.recipientIndex}
                        />
                        <p>{signatureTemplateField.fieldType}</p>
                    </>
                )}
            </div>
            {isHighlighted ? (
                <ArrowLeftIcon style={arrowStyles} className={arrowClasses} />
            ) : null}
        </>
    );
};

interface RecipientBoxProps {
    recipient: Recipient;
    index: number;
    recipientFields: GetSignatureTemplateQuery["getSignatureTemplate"]["signatureTemplateFields"];
    potentialRecipients: GetDraftSignatureRequestQuery["getDraftSignatureRequest"]["potentialRecipients"];
    onNameChange: (value: string) => void;
    onEmailChange: (value: string) => void;
    onSelectField: (fieldId: SignatureTemplateFieldId) => void;
    onIsRequiredChange: (
        fieldId: SignatureTemplateFieldId,
        value: boolean | null,
    ) => void;
    showPage: boolean;
}

type RecipientMode = "choose" | "manual";

const RecipientBox: React.FC<RecipientBoxProps> = props => {
    const {
        recipientFields,
        potentialRecipients,
        index,
        recipient,
        onNameChange,
        onEmailChange,
        onSelectField,
        onIsRequiredChange,
        showPage,
    } = props;

    const allowChoose = potentialRecipients?.length > 0;
    const [selectedRecipientIndex, setSelectedRecipientIndex] = useState<number | null>(
        index < potentialRecipients?.length ? index : null,
    );
    const [mode, setMode] = useState<RecipientMode>(
        typeof selectedRecipientIndex === "number" &&
            recipient.name === "" &&
            recipient.email === ""
            ? "choose"
            : "manual",
    );

    useEffect(() => {
        if (mode === "choose" && typeof selectedRecipientIndex === "number") {
            const selectedRecipient = potentialRecipients[selectedRecipientIndex];
            if (selectedRecipient) {
                onNameChange(potentialRecipients[selectedRecipientIndex].name);
                onEmailChange(potentialRecipients[selectedRecipientIndex].email);
            }
        }
    }, [mode, selectedRecipientIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    const externalPotentialRecipients = potentialRecipients.filter(
        r => r.source === "entity",
    );
    const internalPotentialRecipients = potentialRecipients.filter(
        r => r.source === "user",
    );

    return (
        <li className={getInlineSignatureBackgroundStyle(index + 1)}>
            <div className="font-bold">Recipient {index + 1}</div>
            {allowChoose ? (
                <div className="flex items-center justify-between">
                    <Radio
                        checked={mode === "choose"}
                        label="Choose recipient"
                        onChange={() => {
                            setMode("choose");
                            setSelectedRecipientIndex(selectedRecipientIndex ?? 0);
                        }}
                    />
                    <Radio
                        checked={mode === "manual"}
                        label="Enter manually"
                        onChange={() => setMode("manual")}
                    />
                </div>
            ) : null}
            {mode === "choose" ? (
                <Select
                    value={selectedRecipientIndex}
                    onChange={e =>
                        setSelectedRecipientIndex(parseInt(e.target.value, 10))
                    }>
                    {externalPotentialRecipients.length > 0 ? (
                        <optgroup label="External">
                            {externalPotentialRecipients.map(
                                (potentialRecipient, potentialRecipientIndex) => (
                                    <option
                                        key={potentialRecipientIndex}
                                        value={potentialRecipientIndex}>
                                        {`${potentialRecipient.name} (${potentialRecipient.email})`}
                                    </option>
                                ),
                            )}
                        </optgroup>
                    ) : null}
                    {internalPotentialRecipients.length > 0 ? (
                        <optgroup label="Internal">
                            {internalPotentialRecipients.map(
                                (potentialRecipient, potentialRecipientIndex) => (
                                    <option
                                        key={potentialRecipientIndex}
                                        value={
                                            potentialRecipientIndex +
                                            externalPotentialRecipients.length
                                        }>
                                        {`${potentialRecipient.name} (${potentialRecipient.email})`}
                                    </option>
                                ),
                            )}
                        </optgroup>
                    ) : null}
                </Select>
            ) : null}
            {mode === "manual" ? (
                <>
                    <Input
                        required={true}
                        label={<span className="text-sm">Name</span>}
                        value={recipient.name}
                        onChange={e => onNameChange(e.target.value)}
                    />
                    <Input
                        required={true}
                        label={<span className="text-sm">Email</span>}
                        value={recipient.email}
                        onChange={e => onEmailChange(e.target.value)}
                    />
                </>
            ) : null}
            {recipientFields.length > 0 ? (
                <p className="text-sm">
                    Recipient {index + 1} will complete these fields:
                </p>
            ) : null}
            <ul>
                {recipientFields.map(f => (
                    <li className="flex flex-col space-y-1 items-start" key={f.id}>
                        <div className="flex items-center space-x-1">
                            <SignatureFieldIcon
                                recipientIndex={f.recipientIndex}
                                fieldType={f.fieldType}
                            />
                            <a
                                href="#"
                                className={getSignatureLinkStyle(f.recipientIndex)}
                                onClick={e => {
                                    e.preventDefault();
                                    onSelectField(f.id);
                                }}>
                                {f.fieldType}
                            </a>
                            {showPage ? (
                                <p
                                    className={`${getSignatureLinkMetadataStyle(
                                        f.recipientIndex,
                                    )} text-xs pl-2 pt-0.5`}>
                                    Page {f.page}
                                </p>
                            ) : null}
                        </div>
                        {f.fieldType === SignatureFieldType.Checkbox ? (
                            <Checkbox
                                label="Must be checked"
                                className="flex items-center !mt-0 !mb-2"
                                checked={f.isRequired}
                                onChange={e => {
                                    onSelectField(f.id);
                                    onIsRequiredChange(f.id, e.target.checked);
                                }}
                            />
                        ) : null}
                    </li>
                ))}
            </ul>
        </li>
    );
};
