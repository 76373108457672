import { AdvancedSearchDownload } from "components/advanced-search/advanced-search-download";
import { AdvancedSearchInput } from "components/advanced-search/advanced-search-input";
import { AdvancedSearchResults } from "components/advanced-search/advanced-search-results";
import { AdvancedSearchSelect } from "components/advanced-search/advanced-search-select";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentContainer, ContentFull, SectionHeader } from "components/layout";
import { AdvancedSearchRequest, useGetEntitiesQuery } from "generated/graphql";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "store/actions";
import { ApplicationState } from "store/state";
import { stableQueryOptions } from "util/requests";
import { handleEvent } from "util/user-events";

interface Props {
    canSaveAdditional: boolean;
}

export const CustomReportsNew: React.FC<Props> = props => {
    const { canSaveAdditional } = props;
    const entitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());

    const dispatch = useDispatch();

    const cacheNewCustomReportInput = useCallback(
        (input: AdvancedSearchRequest) => {
            dispatch({
                type: Actions[Actions.REPORT_PARAMETER_CUSTOM_NEW_REPORT_INPUT_UPDATE],
                value: input,
            });
        },
        [dispatch],
    );

    const searchInputWorkingCopy = useSelector(
        (state: ApplicationState) => state.reportParameters.custom.newReportInput,
    );
    const [committedSearchInput, setCommittedSearchInput] =
        useState<AdvancedSearchRequest>(searchInputWorkingCopy);

    useEffect(() => {
        handleEvent("view-adhoc-report-results");
    }, [committedSearchInput]);

    return (
        <QueryRenderer query={entitiesQuery} name="CustomReportsNew.getEntities">
            {data => (
                <div className="flex-1">
                    <SectionHeader title="Custom report">
                        <div className="flex items-center space-x-3">
                            <AdvancedSearchDownload
                                searchRequest={committedSearchInput}
                            />
                            {searchInputWorkingCopy?.select ? (
                                <AdvancedSearchSelect
                                    request={searchInputWorkingCopy}
                                    entities={data.getEntities}
                                    entityType={searchInputWorkingCopy.entityType}
                                    onChange={setCommittedSearchInput}
                                />
                            ) : null}
                        </div>
                    </SectionHeader>
                    <ContentContainer className="space-y-2 lg:space-y-4">
                        <AdvancedSearchInput
                            onCommit={setCommittedSearchInput}
                            entities={data.getEntities}
                            onChange={cacheNewCustomReportInput}
                            initial={searchInputWorkingCopy}
                            committed={committedSearchInput}
                            canSaveAdditional={canSaveAdditional}
                        />
                        {committedSearchInput ? (
                            <ContentFull>
                                <AdvancedSearchResults
                                    onChangeRequest={setCommittedSearchInput}
                                    request={committedSearchInput}
                                    includeIconLinks={true}
                                    includeSummaries={true}
                                />
                            </ContentFull>
                        ) : null}
                    </ContentContainer>
                </div>
            )}
        </QueryRenderer>
    );
};
