import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import * as C from "@sp-crm/core";
import {
    ClientId,
    CommunityId,
    EmailTemplateId,
    FileEntityId,
    FileId,
    IClient,
    IContact,
    InternalId,
    emailTokens,
    genInternalId,
} from "@sp-crm/core";
import { PlacementEditor } from "components/editor/editor";
import { Template } from "components/editor/template";
import { Attachments } from "components/messages/attachments";
import { MandatoryEmailToken } from "components/messages/mandatory-token";
import { MessageComposeBasic } from "components/messages/message-compose-basic";
import { RecipientsField } from "components/messages/recipients-field";
import { Checkbox } from "components/ui/checkbox";
import { fancyAlert } from "components/ui/fancy-alert";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { Input } from "components/ui/input";
import { defaultLinkStyle } from "components/ui/link";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import {
    AdvancedSearchEntityType,
    GetClientForIntakeEmailQuery,
} from "generated/graphql";
import { produce } from "immer";
import * as React from "react";
import { inlineStylesForDeliver } from "util/email";
import { handleEvent } from "util/user-events";
import { defaultRequestDescription } from "../../../../store/reducers/status";
import { RequestDescription } from "../../../../types/request-description";
import { BottomPanel } from "../email-components/bottom-panel";
import {
    discardEmailConfirmation,
    sendEmptySubjectConfirmation,
} from "../email-components/confirm-dialog";
import { SelectCommunities } from "../email-components/select-communities";
import {
    CommunityRecipient,
    SelectCommunityRecipients,
    buildCommunityRecipient,
} from "../email-components/select-community-recipients";
import { ClientReferralPdfCustomization, defaultClientPDFOptions } from "./customize";

export interface ClientIntakeEmailDialogProps {
    bulkEmailEnabled: boolean;
    clientCommunities: GetClientForIntakeEmailQuery["getClient"]["clientCommunities"];
    client: IClient;
    clientDisclosureFileId: FileId | null;
    clientId: ClientId;
    clientOnly: boolean;
    disclosureEnabled: boolean;
    files: { entityName: string; files: C.IFile[] }[];
    inClientIntakeWorkflow: boolean;
    includeDisclosure: boolean;
    initialTemplateId?: EmailTemplateId | null;
    loadFiles: (entityId: FileEntityId) => void;
    onDismiss: () => void;
    recipients?: string[];
    send: (eventId: InternalId, data: C.ClientIntakeMessage) => void;
    userPreferences: C.UserPreferences;
    requests: { [key: string]: RequestDescription };
    userContacts: IContact[];
}

interface ClientIntakeEmailDialogState {
    message: C.MessageOptions;
    recipientSuggestions: C.IContact[];
    communityRecipients: CommunityRecipient[];
    options: C.ClientIntakeCustomizationOptions;
    dirtyRecipientList: boolean;
    eventId: InternalId;
    includeIntake: boolean;
    dirty: boolean;
    showCc: boolean;
}

export class ClientIntakeEmailDialogInternal extends React.Component<
    ClientIntakeEmailDialogProps,
    ClientIntakeEmailDialogState
> {
    private editor: React.MutableRefObject<PlacementEditor> = React.createRef();

    constructor(p: ClientIntakeEmailDialogProps) {
        super(p);
        this.state = {
            eventId: genInternalId(),
            options: defaultClientPDFOptions(p.client, this.props.userPreferences),
            message: this.buildMessage(p),
            recipientSuggestions: p.recipients
                ? p.recipients.map(r => ({ name: r, email1: r } as C.IContact))
                : [],
            communityRecipients: this.props.clientCommunities
                .filter(cc => cc.relationship !== C.CommunityRelationshipType.excluded)
                .map(buildCommunityRecipient),
            dirtyRecipientList: false,
            includeIntake: this.props.inClientIntakeWorkflow,
            dirty: false,
            showCc: false,
        };
        this.dismiss = this.dismiss.bind(this);
        this.newEmail = this.newEmail.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.onSubjectChange = this.onSubjectChange.bind(this);
        this.onRecipientsChange = this.onRecipientsChange.bind(this);
        this.onBccMyselfChange = this.onBccMyselfChange.bind(this);
    }

    componentDidMount(): void {
        this.props.loadFiles(this.props.client.id);
    }

    private requestDescription(): RequestDescription {
        return this.props.requests[this.state.eventId] || defaultRequestDescription();
    }

    private buildMessage(p: ClientIntakeEmailDialogProps): C.MessageOptions {
        return {
            recipients: p.recipients
                ? p.recipients.map(r => {
                      return { name: r, email1: r } as C.IContact;
                  })
                : [],
            subject: "",
            body: "",
            cc: [],
            bcc: [],
            from: null,
            bccMyself: this.props.userPreferences.bccMyself,
            communityId: null,
            attachments: [],
        };
    }

    private dismiss(delay = 0): void {
        const doDismiss = () => setTimeout(this.props.onDismiss.bind(this), delay);
        if (this.state.dirty) {
            (async () => {
                const result = await discardEmailConfirmation();
                if (result) {
                    doDismiss();
                }
            })();
        } else {
            doDismiss();
        }
    }

    private pdfCustomizationOptions(): C.ClientIntakeCustomizationOptions {
        return this.state.options;
    }

    private updateOptions(options: C.ClientIntakeCustomizationOptions): void {
        this.setState({ ...this.state, options, dirty: true });
    }

    private sendMessage() {
        const body = inlineStylesForDeliver(this.editor.current.getContent());
        const message: C.ClientIntakeMessage = {
            pdfOptions: this.state.options,
            clientId: this.props.client.id,
            messageOptions: this.props.bulkEmailEnabled
                ? this.state.communityRecipients
                      .filter(communityRecipient => communityRecipient.isSelected)
                      .map(communityRecipient => ({
                          ...this.state.message,
                          body,
                          communityId: communityRecipient.community.id,
                          recipients: communityRecipient.recipients,
                      }))
                : [
                      {
                          ...this.state.message,
                          body,
                      },
                  ],
            includeIntake: this.state.includeIntake,
            includeDisclosureLink: this.props.includeDisclosure,
            attachments: [],
        };

        if (!this.hasRecipients()) {
            fancyAlert(
                "Error: Missing Recipients",
                "Either no community recipients are selected, or some selected communities have no associated recipients. Please Edit recipients to correct this error and then send.",
            );
            return;
        }

        if (this.props.includeDisclosure && !body.includes(emailTokens.intakeLiveLink)) {
            fancyAlert(
                "Link Placeholder Missing",
                `To send an email with a disclosure link, the message body must contain ${emailTokens.intakeLiveLink}. Please add ${emailTokens.intakeLiveLink} to the body and then send.`,
            );
            return;
        }

        const doSend = () => {
            handleEvent("message-send", { source: "client-intake-email" });
            this.props.send(this.state.eventId, message);
            this.setState({ ...this.state, dirty: false });
        };
        if (!this.state.message.subject || this.state.message.subject === "") {
            (async () => {
                const result = await sendEmptySubjectConfirmation();
                if (result) {
                    doSend();
                }
            })();
        } else {
            doSend();
        }
    }

    private hasRecipients(): boolean {
        const hasValidRecipients = () => {
            let atLeastOneCommunitySelected = false;
            let missingSomeRecips = false;

            this.state.communityRecipients.forEach(communityRecips => {
                if (missingSomeRecips) {
                    // if we're already missing some recips, we don't need
                    // to do any other checks in this loop.
                    return;
                }

                if (communityRecips.isSelected) {
                    atLeastOneCommunitySelected = true;

                    if (
                        !communityRecips.recipients ||
                        communityRecips.recipients.length === 0
                    ) {
                        // this community is selected, but it has no recips
                        missingSomeRecips = true;
                    }
                }
            });

            return atLeastOneCommunitySelected && !missingSomeRecips;
        };

        if (this.props.bulkEmailEnabled) {
            return hasValidRecipients();
        } else {
            return (
                this.state.message.recipients?.length > 0 ||
                this.state.message.cc?.length > 0
            );
        }
    }

    private updateSelectedCommunity(id: CommunityId): void {
        const doChange = () => {
            const communityRecipient: CommunityRecipient = this.props
                .inClientIntakeWorkflow
                ? ClientIntakeEmailDialogInternal.recipientsFor(
                      this.props.client.allCommunities,
                      id,
                  )
                : null;
            const newMessage = {
                ...this.state.message,
                communityId: id,
                recipients: communityRecipient?.recipients ?? [],
            };
            const newState = {
                ...this.state,
                message: newMessage,
                recipientSuggestions: communityRecipient?.contacts ?? [],
                dirtyRecipientList: false,
                dirty: true,
            };
            this.setState(newState);
        };
        if (this.state.dirtyRecipientList) {
            (async () => {
                const result = await fancyConfirm(
                    "Change community?",
                    "You've updated TO: line; if you change communities, these changes will be lost. Are you sure you want to proceed?",
                    "Yes, I'm sure",
                    "Cancel",
                );
                if (result) {
                    doChange();
                }
            })();
        } else {
            doChange();
        }
    }

    private static recipientsFor(
        communities: C.ICommunity[],
        communityId: CommunityId,
    ): CommunityRecipient | null {
        if (!communityId || communityId === "") {
            return null;
        }

        const community = communities.find(c => c.id === communityId);
        if (!community) {
            return null;
        }

        return {
            community: {
                id: community.id,
                name: community.name ?? "",
            },
            contacts: [
                C.Contact.load({
                    name: community.email ?? "",
                    email1: community.email ?? "",
                    email1OptOut: community.emailOptOut,
                }),
                ...community.contactEntities.map(contact =>
                    C.Contact.load({
                        name: contact.name ?? "",
                        email1: contact.email1 ?? "",
                        email1OptOut: contact.email1OptOut,
                    }),
                ),
            ].filter(c => !!c.email1),
            isSelected: true,
            recipients: [
                C.Contact.load({
                    name: community.email ?? "",
                    email1: community.email ?? "",
                    email1OptOut: community.emailOptOut,
                }),
                ...community.primaryContactEntities.map(contact =>
                    C.Contact.load({
                        name: contact.name ?? "",
                        email1: contact.email1 ?? "",
                        email1OptOut: contact.email1OptOut,
                    }),
                ),
            ].filter(c => !!c.email1 && !c.email1OptOut),
        };
    }

    private newEmail(): void {
        const newMessage: C.MessageOptions = {
            ...this.state.message,
            recipients: [],
        };

        this.setState({
            ...this.state,
            eventId: genInternalId(),
            message: newMessage,
            dirty: false,
        });
    }

    private attachmentForm(): JSX.Element {
        return (
            <Attachments
                scopes={this.props.files}
                selectedFileIds={this.state.message.attachments}
                onSelectFile={(fileId: FileId) => {
                    const isFileSelected =
                        this.state.message.attachments.includes(fileId);
                    this.setState({
                        message: {
                            ...this.state.message,
                            attachments: isFileSelected
                                ? this.state.message.attachments.filter(a => fileId !== a)
                                : [...this.state.message.attachments, fileId],
                        },
                    });
                }}
            />
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    private onSubjectChange(e: any) {
        const message = this.state.message;
        const newMessage = {
            ...message,
            subject: e.target.value,
        };
        this.setState({
            message: newMessage,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    private onBccMyselfChange(e: any) {
        const message = this.state.message;
        const newMessage = {
            ...message,
            bccMyself: e.target.checked,
        };
        this.setState({
            message: newMessage,
        });
    }

    private onRecipientsChange(contacts: IContact[]): void {
        const newMessage = {
            ...this.state.message,
            recipients: contacts,
        };
        this.setState({ message: newMessage, dirtyRecipientList: true });
    }

    private onTemplateInserted(template: Template): void {
        const newMessage: C.MessageOptions = produce(this.state.message, draft => {
            if (!draft.subject && template.subject) {
                draft.subject = template.subject;
            }

            if (Array.isArray(template.attachments)) {
                template.attachments.forEach(attachmentId => {
                    if (!draft.attachments.includes(attachmentId)) {
                        draft.attachments.push(attachmentId);
                    }
                });
            }
        });

        this.setState({ message: newMessage });
    }

    private cc(): JSX.Element {
        if (this.state.showCc) {
            return (
                <RecipientsField
                    label="Cc"
                    recipients={this.state.message.cc}
                    suggestions={[
                        ...this.state.recipientSuggestions,
                        ...this.props.userContacts,
                    ]}
                    onChange={(contacts: IContact[]) => {
                        const newMessage = {
                            ...this.state.message,
                            cc: contacts,
                        };
                        this.setState({ message: newMessage });
                    }}
                />
            );
        }

        return (
            <div className="flex items-center justify-end space-x-2 mt-1">
                <a
                    className={defaultLinkStyle}
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        this.setState({ showCc: true });
                    }}>
                    Cc
                </a>
            </div>
        );
    }

    render() {
        const headerText =
            "Email Client Info for " + this.props.client.preferredClientName;
        const attachLabel = `Attach PDF with Client Intake details`;
        return (
            <>
                <Panel
                    type={PanelType.extraLarge}
                    hasCloseButton={false}
                    isOpen={true}
                    headerText={headerText}>
                    <div className="pdf-dialog-panel">
                        <div className="space-y-4">
                            {this.props.bulkEmailEnabled ? (
                                <>
                                    <SelectCommunityRecipients
                                        communityRecipients={
                                            this.state.communityRecipients
                                        }
                                        onUpdateCommunityRecipients={newValues => {
                                            this.setState({
                                                communityRecipients: newValues,
                                            });
                                        }}
                                        previouslyContactedCommunities={this.props.clientCommunities
                                            .filter(cc => cc.referred)
                                            .map(cc => cc.community.id)}
                                    />
                                    {this.cc()}
                                </>
                            ) : (
                                <>
                                    {!this.props.clientOnly ? (
                                        <SelectCommunities
                                            communities={this.props.client.allCommunities}
                                            message={this.state.message}
                                            updateSelectedCommunity={this.updateSelectedCommunity.bind(
                                                this,
                                            )}
                                        />
                                    ) : null}
                                    <div>
                                        <RecipientsField
                                            label="To"
                                            recipients={this.state.message.recipients}
                                            suggestions={this.state.recipientSuggestions}
                                            onChange={this.onRecipientsChange}
                                        />
                                        {this.cc()}
                                    </div>
                                </>
                            )}
                        </div>

                        <Input
                            label="Subject"
                            value={this.state.message.subject}
                            onChange={this.onSubjectChange}
                        />

                        <div className="my-4">
                            <MessageComposeBasic
                                ref={this.editor}
                                initialTemplateId={this.props.initialTemplateId}
                                onTemplateInserted={this.onTemplateInserted.bind(this)}
                                entityType={AdvancedSearchEntityType.Client}
                                entityId={this.props.clientId}
                            />
                        </div>
                        {this.props.disclosureEnabled && this.props.includeDisclosure ? (
                            this.props.clientDisclosureFileId ? (
                                <p>
                                    <MandatoryEmailToken use="disclosure" />
                                </p>
                            ) : (
                                <div className="flex space-x-4 text-red-700 my-2">
                                    <ExclamationTriangleIcon className="w-12 h-12" />
                                    <p>
                                        There is no disclosure PDF configured in your
                                        agency settings. Navigate to Agency Settings and
                                        configure a PDF to ensure recipients can
                                        acknowledge your disclosure.
                                    </p>
                                </div>
                            )
                        ) : null}
                        <div className="my-4">
                            <Checkbox
                                label="BCC myself on this email"
                                checked={this.state.message.bccMyself}
                                onChange={this.onBccMyselfChange}
                            />
                        </div>

                        {this.attachmentForm()}
                        <div className="mt-4">
                            <Checkbox
                                label={attachLabel}
                                checked={this.state.includeIntake}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({
                                        includeIntake: e.target.checked,
                                    })
                                }
                            />
                        </div>
                        {this.state.includeIntake ? (
                            <ClientReferralPdfCustomization
                                options={this.pdfCustomizationOptions()}
                                callback={o => this.updateOptions(o)}
                            />
                        ) : null}
                        <div className="pdf-dialog-panel--section">
                            <BottomPanel
                                requestDescription={this.requestDescription()}
                                dismiss={this.dismiss}
                                sendMessage={this.sendMessage}
                                newMessage={this.newEmail}
                                sendEnabled={
                                    !this.props.includeDisclosure ||
                                    !!this.props.clientDisclosureFileId
                                }
                            />
                        </div>
                    </div>
                </Panel>
            </>
        );
    }
}
