import { ActionType } from "@sp-crm/core";
import { ContentContainer } from "components/layout";
import { SubnavMenu, SubnavMenuItem } from "components/navigation/subnav";
import React from "react";
import {
    useFeature,
    useIsAllowed,
    useIsAllowedInWholeTenant,
} from "store/selectors/hooks";

export const SettingsNavigation: React.FC<unknown> = () => {
    const editAgencySettingsAllowed = useIsAllowedInWholeTenant(ActionType.UpdateTenant);
    const manageUsersAllowed = useIsAllowed(ActionType.SelfManageModifyUser);
    const manageIntegrationsAllowed = useIsAllowedInWholeTenant(
        ActionType.ManageIntegrations,
    );
    const workflowsEnable = useFeature("workflows");
    const editWorkflowsAllowed = useIsAllowed(ActionType.CreateWorkflow);

    const showWorkflows = workflowsEnable && editWorkflowsAllowed;

    return (
        <ContentContainer className="mb-4">
            <SubnavMenu>
                <SubnavMenuItem to="/settings/account">My settings</SubnavMenuItem>
                <SubnavMenuItem to="/settings/templates">
                    Email Templates & Signatures
                </SubnavMenuItem>
                {editAgencySettingsAllowed ? (
                    <SubnavMenuItem to="/settings/agency">Agency settings</SubnavMenuItem>
                ) : null}
                {showWorkflows ? (
                    <SubnavMenuItem to="/settings/workflow">Workflows</SubnavMenuItem>
                ) : null}
                {manageUsersAllowed ? (
                    <SubnavMenuItem to="/settings/users">User accounts</SubnavMenuItem>
                ) : null}
                {manageIntegrationsAllowed ? (
                    <SubnavMenuItem to="/settings/integrations">
                        Integrations
                    </SubnavMenuItem>
                ) : null}
            </SubnavMenu>
        </ContentContainer>
    );
};
