import {
    DocumentMagnifyingGlassIcon,
    MagnifyingGlassMinusIcon,
    MagnifyingGlassPlusIcon,
} from "@heroicons/react/20/solid";
import { SecondaryButton } from "components/ui/secondary-button";
import { produce } from "immer";
import React, { useEffect, useMemo } from "react";
import { Document, Page } from "react-pdf";
import { DocumentCallback, PageCallback } from "react-pdf/dist/cjs/shared/types";
import { useWindowSize } from "react-use";
import { setupPdfjs } from "./pdfjs-setup";

setupPdfjs();

export interface DocumentPdfInfo {
    EncryptFilterName?: string;
}

export interface PdfDocumentProxyMetadata {
    info: DocumentPdfInfo;
    metadata: unknown;
}

interface DocumentRenderProps {
    pageNumber: number | null | undefined;
    documentUrl: string;
    onLoadSuccess?: (pdf: DocumentCallback) => void;
    onRenderSuccess?: (page: PageCallback) => void;
    setPageNumber?: (pageNumber: number) => void;
    setZoomLevel?: (zoomLevel: number) => void;
    extraControls?: JSX.Element | null;
    responsiveControls?: boolean;
    children?: React.ReactNode;
}

const TAILWIND_MD_BREAKPOINT = 768;
const DOCUMENT_LAYOUT_CHROME_WIDTH = 800;

interface State {
    numPages: number | null;
    currentPageDimensions: [number, number] | null;
    finishedInitialLoad: boolean;
    zoomLevel: number;
    windowWidth: number;
    explicitZoomRequested: boolean;
}

const initialState: State = {
    numPages: null,
    currentPageDimensions: null,
    finishedInitialLoad: false,
    zoomLevel: 1,
    windowWidth: 0,
    explicitZoomRequested: false,
};

type Action =
    | { type: "onWindowResize"; windowWidth: number }
    | { type: "zoomIn" }
    | { type: "zoomFit" }
    | { type: "zoomOut" }
    | { type: "zoomCancel" }
    | { type: "onLoadSuccess"; document: DocumentCallback; windowWidth: number }
    | { type: "onRenderSuccess"; page: PageCallback; windowWidth: number };

const reducer = (state: State, action: Action): State =>
    produce(state, draft => {
        if (action.type === "onLoadSuccess") {
            draft.numPages = action.document.numPages;
        }

        if (action.type === "onRenderSuccess") {
            const { originalWidth: width, originalHeight: height } = action.page;
            draft.currentPageDimensions = [width, height];
            if (!state.explicitZoomRequested) {
                if (width > action.windowWidth) {
                    draft.zoomLevel = action.windowWidth / width;
                } else {
                    draft.zoomLevel = 1;
                }
            }
        }

        if (action.type === "onWindowResize") {
            draft.windowWidth = action.windowWidth;
            if (state.currentPageDimensions) {
                const [width] = state.currentPageDimensions;
                if (width > action.windowWidth) {
                    draft.zoomLevel = action.windowWidth / width;
                } else {
                    draft.zoomLevel = 1;
                }
            }
        }

        if (action.type === "zoomIn") {
            draft.zoomLevel = state.zoomLevel + 0.1;
            draft.explicitZoomRequested = true;
        }

        if (action.type === "zoomOut") {
            draft.zoomLevel = state.zoomLevel - 0.1;
            draft.explicitZoomRequested = true;
        }

        if (action.type === "zoomFit") {
            if (state.currentPageDimensions) {
                const [width] = state.currentPageDimensions;
                const effectiveWindowWidth =
                    draft.windowWidth > TAILWIND_MD_BREAKPOINT
                        ? draft.windowWidth - DOCUMENT_LAYOUT_CHROME_WIDTH
                        : draft.windowWidth;
                draft.zoomLevel = effectiveWindowWidth / width;
                draft.explicitZoomRequested = true;
            }
        }

        if (action.type === "zoomCancel") {
            draft.explicitZoomRequested = false;
        }
    });

export const DocumentRender: React.FC<DocumentRenderProps> = props => {
    const {
        pageNumber,
        documentUrl,
        onLoadSuccess,
        onRenderSuccess,
        children,
        setPageNumber,
        extraControls,
        responsiveControls,
        setZoomLevel,
    } = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { width: windowWidth } = useWindowSize();
    useEffect(() => {
        dispatch({ type: "onWindowResize", windowWidth: windowWidth });
    }, [windowWidth]);
    useEffect(() => {
        if (setZoomLevel) {
            setZoomLevel(state.zoomLevel);
        }
    }, [state.zoomLevel, setZoomLevel]);
    const onLoadSuccessLocal = React.useCallback(
        (document: DocumentCallback) => {
            dispatch({ type: "onLoadSuccess", document, windowWidth: windowWidth });
            if (onLoadSuccess) {
                onLoadSuccess(document);
            }
        },
        [onLoadSuccess, windowWidth],
    );
    const onRenderSuccessLocal = React.useCallback(
        (page: PageCallback) => {
            dispatch({ type: "onRenderSuccess", page, windowWidth: windowWidth });
            if (onRenderSuccess) {
                onRenderSuccess(page);
            }
        },
        [onRenderSuccess, windowWidth],
    );
    const prevPage = React.useCallback(() => {
        if (!state.numPages) {
            return;
        }
        if (!setPageNumber) {
            return;
        }
        dispatch({ type: "zoomCancel" });
        if (!pageNumber) {
            setPageNumber(1);
        }
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }, [pageNumber, state.numPages, setPageNumber]);
    const nextPage = React.useCallback(() => {
        if (!state.numPages) {
            return;
        }
        if (!setPageNumber) {
            return;
        }
        dispatch({ type: "zoomCancel" });
        if (!pageNumber) {
            setPageNumber(1);
        }
        if (pageNumber < state.numPages) {
            setPageNumber(pageNumber + 1);
        }
    }, [pageNumber, state.numPages, setPageNumber]);
    const setPage = React.useCallback(
        (pageNumber: number) => {
            if (!setPageNumber) {
                return;
            }
            if (!state.numPages) {
                return;
            }
            dispatch({ type: "zoomCancel" });
            if (pageNumber < 1) {
                setPageNumber(1);
            }
            if (pageNumber > state.numPages) {
                setPageNumber(state.numPages);
            }
            setPageNumber(pageNumber);
        },
        [state.numPages, setPageNumber],
    );
    const zoomIn = React.useCallback(() => {
        dispatch({ type: "zoomIn" });
    }, []);
    const zoomFit = React.useCallback(() => {
        dispatch({ type: "zoomFit" });
    }, []);
    const zoomOut = React.useCallback(() => {
        dispatch({ type: "zoomOut" });
    }, []);
    const computedWidth = useMemo(
        () => (state.currentPageDimensions ?? [0, 0])[0] * state.zoomLevel,
        [state.zoomLevel, state.currentPageDimensions],
    );
    const controlsStyle = useMemo(() => {
        const currentDimensions = state.currentPageDimensions ?? [0, 0];
        const documentBorderPixels = computedWidth < currentDimensions[0] ? 0 : 2;
        return {
            minWidth: Math.min(
                (currentDimensions[0] + documentBorderPixels) * state.zoomLevel,
                state.windowWidth,
            ), // prevents flicker on page change, never wider than the window
        };
    }, [state.currentPageDimensions, computedWidth, state.zoomLevel, state.windowWidth]);
    const documentContainerStyle = useMemo(() => {
        const currentDimensions = state.currentPageDimensions ?? [0, 0];
        const documentBorderPixels = computedWidth < currentDimensions[0] ? 0 : 2;
        return {
            minHeight: (currentDimensions[1] + documentBorderPixels) * state.zoomLevel, // prevents flicker on page change
        };
    }, [state.currentPageDimensions, computedWidth, state.zoomLevel]);
    return (
        <div className="mb-4 space-y-4 pdf-render">
            <div
                className={`hidden md:flex justify-between items-center`}
                style={controlsStyle}>
                <PaginationControls
                    pageNumber={pageNumber}
                    numPages={state.numPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    setPage={setPage}
                    zoomFit={zoomFit}
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    responsiveControls={!!responsiveControls}
                />
                {extraControls}
            </div>
            <div style={documentContainerStyle}>
                <Document
                    className={
                        "relative rounded shadow-sm md:shadow-lg border border-gray-50"
                    }
                    file={documentUrl}
                    onLoadSuccess={onLoadSuccessLocal}>
                    <div className="absolute inset-0 z-40">{children}</div>
                    <div className="z-30 border border-red-100">
                        <Page
                            width={computedWidth}
                            onRenderSuccess={onRenderSuccessLocal}
                            renderTextLayer={false}
                            pageNumber={pageNumber ?? 1}
                            loading={""}
                        />
                    </div>
                </Document>
            </div>
            <div className={`justify-between items-center`} style={controlsStyle}>
                <PaginationControls
                    pageNumber={pageNumber}
                    numPages={state.numPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    setPage={setPage}
                    zoomFit={zoomFit}
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    responsiveControls={!!responsiveControls}
                />
            </div>
        </div>
    );
};

interface PaginationControlsProps {
    pageNumber: number | null | undefined;
    numPages: number | null | undefined;
    prevPage: () => void;
    nextPage: () => void;
    zoomIn: () => void;
    zoomOut: () => void;
    zoomFit: () => void;
    setPage: (pageNumber: number) => void;
    responsiveControls: boolean;
}

const PaginationControls: React.FC<PaginationControlsProps> = props => {
    const {
        pageNumber,
        numPages,
        prevPage,
        nextPage,
        setPage,
        zoomIn,
        zoomOut,
        zoomFit,
        responsiveControls,
    } = props;

    const handlePrev = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            prevPage();
        },
        [prevPage],
    );

    const handleNext = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            nextPage();
        },
        [nextPage],
    );

    const handleZoomIn = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            zoomIn();
        },
        [zoomIn],
    );

    const handleZoomOut = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            zoomOut();
        },
        [zoomOut],
    );

    const handleZoomFit = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            zoomFit();
        },
        [zoomFit],
    );

    return (
        <div className="grid p-2 gap-2 grid-cols-3 grid-rows-2 md:flex md:items-center md:space-x-2 text-sm">
            <SecondaryButton
                className="text-sm"
                disabled={!pageNumber || pageNumber <= 1}
                onClick={handlePrev}>
                Previous
            </SecondaryButton>
            <div className="text-sm flex items-center space-x-1">
                <p>Page</p>
                <PagePicker
                    selectedPage={pageNumber ?? 1}
                    numPages={numPages ?? 1}
                    onChange={setPage}
                />
                <p>of {numPages}</p>
            </div>
            <SecondaryButton
                className="text-sm"
                disabled={!pageNumber || pageNumber >= numPages}
                onClick={handleNext}>
                Next
            </SecondaryButton>
            {responsiveControls ? (
                <div className="flex items-center space-x-1 justify-center col-span-3">
                    <button onClick={handleZoomOut}>
                        <MagnifyingGlassMinusIcon className="h-5 w-5 text-gray-600" />
                    </button>
                    <button onClick={handleZoomFit}>
                        <DocumentMagnifyingGlassIcon className="h-5 w-5 text-gray-600" />
                    </button>
                    <button onClick={handleZoomIn}>
                        <MagnifyingGlassPlusIcon className="h-5 w-5 text-gray-600" />
                    </button>
                </div>
            ) : null}
        </div>
    );
};

interface PagePickerProps {
    selectedPage: number;
    numPages: number;
    onChange: (page: number) => void;
}

export const PagePicker: React.FC<PagePickerProps> = props => {
    const { selectedPage, numPages } = props;

    if (!numPages || numPages <= 1) {
        return <p className="text-sm">{selectedPage}</p>;
    }

    return (
        <select
            className="form-select rounded-sm md:rounded disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300 text-sm py-1 pl-2 pr-8"
            value={selectedPage}
            onChange={e => props.onChange(parseInt(e.target.value, 10))}>
            {Array.from(Array(numPages).keys()).map(index => (
                <option key={index} value={index + 1}>
                    {index + 1}
                </option>
            ))}
        </select>
    );
};
