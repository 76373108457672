import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { AgencySettingsClients } from "./agency-settings-clients";
import { AgencySettingsCommunities } from "./agency-settings-communities";
import { AgencySettingsFax } from "./agency-settings-fax";
import { AgencySettingsGeneral } from "./agency-settings-general";
import { AgencySettingsHostedForm } from "./agency-settings-hosted-form";
import { AgencySettingsInvoices } from "./agency-settings-invoices";
import { AgencySettingsNav } from "./agency-settings-nav";
import { AgencySettingsReferrals } from "./agency-settings-referrals";
import { AgencySettingsSignWise } from "./agency-settings-signwise";
import { AgencySettingsTasks } from "./agency-settings-tasks";
import { Settings } from "./customize";

export const AgencySettings: React.FC<unknown> = () => {
    return (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <div className="lg:col-span-3">
                <AgencySettingsNav />
            </div>
            <div className="lg:col-span-9 pb-4">
                <Switch>
                    <Route
                        path="/settings/agency/general"
                        component={AgencySettingsGeneral}
                    />
                    <Route
                        path="/settings/agency/clients"
                        component={AgencySettingsClients}
                    />
                    <Route
                        path="/settings/agency/communities"
                        component={AgencySettingsCommunities}
                    />
                    <Route
                        path="/settings/agency/referrals"
                        component={AgencySettingsReferrals}
                    />
                    <Route
                        path="/settings/agency/tasks"
                        component={AgencySettingsTasks}
                    />
                    <Route
                        path="/settings/agency/invoices"
                        component={AgencySettingsInvoices}
                    />
                    <Route path="/settings/agency/fax" component={AgencySettingsFax} />
                    <Route
                        path="/settings/agency/customize/cc/:questionId"
                        component={Settings}
                    />
                    <Route path="/settings/agency/customize/cc" component={Settings} />
                    <Route
                        path="/settings/agency/workflow/:workflowId?"
                        render={props => (
                            <Redirect
                                to={props.location.pathname.replace("agency/", "")}
                            />
                        )}
                    />
                    <Route
                        path="/settings/agency/signwise"
                        component={AgencySettingsSignWise}
                    />
                    <Route
                        path="/settings/agency/hosted-form"
                        component={AgencySettingsHostedForm}
                    />
                    <Route
                        path="/settings/agency"
                        render={() => <Redirect to="/settings/agency/general" />}
                    />
                </Switch>
            </div>
        </div>
    );
};
