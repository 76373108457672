import * as Common from "@sp-crm/core";
import { ClientId, Maybe } from "@sp-crm/core";
import { SelectableUser, UserSelect } from "components/shared/user-select";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { usersForCurrentRegion } from "store/selectors/users";
import { ApplicationState } from "store/state";
import { updateClient } from "../../../store/actions";
import util from "../../../util";
import {
    ActionBarComponentRenderer,
    IActionBarComponent,
} from "./../../shared/legacy-action-bar-component-renderer";

interface ActionBarAssignedToOwnProps {
    className?: string;
    client: Common.IClient;
}

interface ActionBarAssignedToPropsFromState extends ActionBarAssignedToOwnProps {
    users: Common.User[];
}

interface ActionBarAssignedToPropsFromDispatch {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
}

type ActionBarAssignedToProps = ActionBarAssignedToPropsFromDispatch &
    ActionBarAssignedToPropsFromState;

class ActionBarAssignedTo
    extends React.Component<ActionBarAssignedToProps>
    implements IActionBarComponent
{
    private forceDismiss: () => void = null;

    public constructor(p: ActionBarAssignedToProps) {
        super(p);
    }

    displayLabel(): string {
        return "Assigned to";
    }

    displayValue(): string {
        let assignedUserDisplayName = "(unassigned)";
        this.props.users.some(x => {
            if (x.id == this.props.client.assignedUserId) {
                assignedUserDisplayName = util.user.displayName(x);
                return true;
            }
            return false;
        });

        return assignedUserDisplayName;
    }

    onCommitChange(user: SelectableUser) {
        this.props.onFieldChange("assignedUserId", user.length > 0 ? user : null);

        if (this.forceDismiss) {
            this.forceDismiss();
        }
    }

    editableContent(): JSX.Element {
        return (
            <UserSelect
                label="Assigned to"
                includeEveryone={false}
                includeUnassigned={true}
                unassignedLabel="(unassigned)"
                value={this.props.client.assignedUserId || ""}
                onChange={this.onCommitChange.bind(this)}
            />
        );
    }

    public render() {
        return (
            <ActionBarComponentRenderer
                actionBarObject={this}
                setForceDismiss={forceDismiss => (this.forceDismiss = forceDismiss)}
            />
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: ActionBarAssignedToOwnProps,
): ActionBarAssignedToPropsFromState {
    return {
        ...ownProps,
        users: util.mapToArray(usersForCurrentRegion(state)),
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<Action>,
    ownProps: ActionBarAssignedToOwnProps,
): ActionBarAssignedToPropsFromDispatch {
    const clientId: ClientId = Maybe.fromValue(ownProps)
        .map(p => p.client)
        .map(c => c.id)
        .getOrElse(null);
    const val = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        onFieldChange: (fieldName: string, newValue: any) => {
            if (clientId) {
                updateClient(clientId, fieldName, newValue, dispatch);
            }
        },
    };
    return val;
}
const component = connect(mapStateToProps, mapDispatchToProps)(ActionBarAssignedTo);
export { component as ActionBarAssignedTo };
