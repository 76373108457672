import { WorkflowId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader } from "components/layout";
import {
    activeLinkStyle,
    linkStyle,
} from "components/reports/show/custom-reports/custom-reports-sidebar";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { Pill } from "components/ui/pill";
import { PrimaryButton } from "components/ui/primary-button";
import { WorkflowExecutionList } from "components/workflow/workflow-execution-list";
import { WorkflowStatus, useGetWorkflowsQuery } from "generated/graphql";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { navigate } from "store/actions";
import { useFeature } from "store/selectors/hooks";
import { CreateWorkflowPanel } from "./create-workflow-panel";
import { SingleWorkflowSettings } from "./single-workflow-settings";

interface Props {
    scope: "user" | "tenant";
}
export const WorkflowSettings: React.FC<Props> = props => {
    const { scope } = props;
    const [creatingWorkflow, setCreatingWorkflow] = React.useState(false);
    const { params } = useRouteMatch<{ workflowId?: WorkflowId }>();
    const workflows = useGetWorkflowsQuery();
    const workflowsEnabled = useFeature("workflows");

    const handleCreateWorkflow = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setCreatingWorkflow(true);
        },
        [setCreatingWorkflow],
    );

    const handleRefetch = React.useCallback(() => {
        workflows.refetch();
    }, [workflows]);

    const selectedWorkflowId = params?.workflowId || null;

    if (!workflowsEnabled) {
        return null;
    }

    return (
        <div className="space-y-4">
            {scope === "tenant" ? (
                <ContentFull>
                    <ContentHeader>Workflows</ContentHeader>
                    <div className="space-y-4">
                        <p>
                            Automate important processes with Workflows. Automatically
                            send follow-up emails, create follow-up tasks, and more.
                        </p>
                        <PrimaryButton onClick={handleCreateWorkflow}>
                            Create workflow
                        </PrimaryButton>
                    </div>
                </ContentFull>
            ) : null}
            <QueryRenderer name="WorkflowSettings" query={workflows}>
                {data => {
                    const selectedWorkflow =
                        data.getWorkflows.find(w => w.id === selectedWorkflowId) ||
                        data.getWorkflows[0] ||
                        null;

                    if (!selectedWorkflow) {
                        return null;
                    }

                    return (
                        <div className="space-y-4">
                            <ContentFull>
                                <ContentHeader>Your Workflows</ContentHeader>
                                <div className="lg:grid lg:grid-cols-3 lg:gap-x-4">
                                    <ul className="lg:col-span-1 space-y-2 max-h-96 overflow-y-scroll">
                                        {data.getWorkflows.map(workflow => {
                                            return (
                                                <li key={workflow.id} className="pr-4">
                                                    <Link
                                                        className={
                                                            workflow.id ===
                                                            selectedWorkflow.id
                                                                ? activeLinkStyle
                                                                : linkStyle
                                                        }
                                                        to={`/settings/${
                                                            scope === "tenant"
                                                                ? ""
                                                                : "account/"
                                                        }workflow/${workflow.id}`}>
                                                        <p className="space-x-2">
                                                            <span>{workflow.name}</span>
                                                            {workflow.status ===
                                                            WorkflowStatus.Draft ? (
                                                                <Pill>Draft</Pill>
                                                            ) : null}
                                                        </p>
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <div className="lg:col-span-2">
                                        <SingleWorkflowSettings
                                            workflow={selectedWorkflow}
                                            scope={scope}
                                            refetch={handleRefetch}
                                            workflowSettings={data.getWorkflowSettings}
                                            allWorkflows={data.getWorkflows}
                                        />
                                    </div>
                                </div>
                            </ContentFull>
                            {scope === "user" ? (
                                <ContentFull>
                                    <ContentHeader>
                                        Pending workflows for my clients
                                    </ContentHeader>
                                    <WorkflowExecutionList />
                                </ContentFull>
                            ) : null}
                        </div>
                    );
                }}
            </QueryRenderer>
            <Panel
                type={PanelType.extraLarge}
                headerText="Create workflow"
                isOpen={creatingWorkflow}
                onDismiss={() => setCreatingWorkflow(false)}>
                <CreateWorkflowPanel
                    onSaved={newWorkflow => {
                        setCreatingWorkflow(false);
                        workflows.refetch();
                        navigate(`/settings/workflow/${newWorkflow.id}`);
                    }}
                    onCancel={() => setCreatingWorkflow(false)}
                />
            </Panel>
        </div>
    );
};
