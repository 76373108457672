import { NoteEntityId, NoteId } from "@sp-crm/core";
import { ActivityFeed } from "components/activity-feed/activity-feed";
import { ActivityFeedNote } from "components/activity-feed/activity-feed-note";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import {
    GetNotesForEntityQuery,
    UpdateNoteMutation,
    useGetNotesForEntityQuery,
} from "generated/graphql";
import { produce } from "immer";
import React from "react";
import { useQueryClient } from "react-query";
import { NotesInput } from "./notes-input";

interface ModernNotesProps {
    entityId: NoteEntityId;
}

export const ModernNotes: React.FC<ModernNotesProps> = props => {
    const { entityId } = props;
    const query = useGetNotesForEntityQuery({ entityId });
    const queryClient = useQueryClient();
    const [localDeletes, setLocalDeletes] = React.useState<NoteId[]>([]);

    const handleNoteChanged = React.useCallback(
        (note: GetNotesForEntityQuery["getNotesForEntity"][0]) => {
            queryClient.setQueryData<GetNotesForEntityQuery>(
                ["getNotesForEntity", { entityId }],
                data => {
                    return produce(data, draft => {
                        let replaced = false;
                        draft.getNotesForEntity = draft.getNotesForEntity.map(n => {
                            if (n._id === note._id) {
                                replaced = true;
                                return note;
                            }
                            return n;
                        });

                        if (!replaced) {
                            draft.getNotesForEntity.unshift(note);
                        }
                    });
                },
            );
        },
        [queryClient, entityId],
    );

    const handleNoteDeleted = React.useCallback((noteId: NoteId) => {
        setLocalDeletes(d => [...d, noteId]);
    }, []);

    const handleNoteUpdated = React.useCallback(
        (note: UpdateNoteMutation["updateNote"]) => {
            queryClient.setQueryData<GetNotesForEntityQuery>(
                ["getNotesForEntity", { entityId }],
                data => {
                    return produce(data, draft => {
                        draft.getNotesForEntity = draft.getNotesForEntity.map(n => {
                            if (n._id === note._id) {
                                return note;
                            }
                            return n;
                        });
                    });
                },
            );
        },
        [queryClient, entityId],
    );

    return (
        <QueryRenderer name="ModernNotes" query={query}>
            {data => (
                <div>
                    <NotesInput
                        refetch={query.refetch}
                        entityId={entityId}
                        initial={data.getNotesForEntity
                            .filter(n => !localDeletes.includes(n._id))
                            .sort(
                                (a, b) =>
                                    new Date(b.dateAdded).getTime() -
                                    new Date(a.dateAdded).getTime(),
                            )}
                        onNoteChanged={handleNoteChanged}
                    />
                    <ActivityFeed>
                        {data.getNotesForEntity
                            .filter(n => !n.isDraft && !localDeletes.includes(n._id))
                            .sort(
                                (a, b) =>
                                    new Date(b.dateAdded).getTime() -
                                    new Date(a.dateAdded).getTime(),
                            )
                            .map(n => (
                                <ActivityFeedNote
                                    entityId={entityId}
                                    refetch={query.refetch}
                                    key={n._id}
                                    note={n}
                                    onDeleted={handleNoteDeleted}
                                    onUpdated={handleNoteUpdated}
                                />
                            ))}
                    </ActivityFeed>
                </div>
            )}
        </QueryRenderer>
    );
};
