import { EmailTemplateId, FileId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { TemplateAttachments } from "components/manage/templates/template-attachments";
import { AutosavingInput } from "components/ui/autosaving-input";
import { HtmlInput } from "components/ui/html-input";
import { InlineBanner } from "components/ui/inline-banner";
import {
    AdvancedSearchEntityType,
    useCreateOrUpdateTemplateMutation,
    useGetTemplateQuery,
    useValidateTemplateQuery,
} from "generated/graphql";
import React, { useEffect, useMemo, useState } from "react";

interface InlineEmailTemplateSettingProps {
    subjectLabel: string;
    bodyLabel: string;
    attachmentLabel?: string;
    value: EmailTemplateId | null;
    onChange: (value: EmailTemplateId | null) => void;
    messageType?: string;
    includeAttachments?: boolean;
    placeholderTypes?: AdvancedSearchEntityType[];
    disabled?: boolean;
}

export const InlineEmailTemplateSetting: React.FC<
    InlineEmailTemplateSettingProps
> = props => {
    const {
        value,
        onChange,
        bodyLabel,
        subjectLabel,
        attachmentLabel,
        messageType,
        includeAttachments,
        placeholderTypes,
        disabled,
    } = props;

    const upsertTemplateMutation = useCreateOrUpdateTemplateMutation();

    const getTemplate = useGetTemplateQuery(
        { id: value },
        { enabled: !!value, keepPreviousData: true },
    );

    const initiallyEnabled = useMemo(() => {
        return !!value;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [attachmentIds, setAttachmentIds] = useState<FileId[]>([]);

    const waitForLoad = initiallyEnabled && !getTemplate.data;
    const isValidationEnabled = !!(value && messageType);

    useEffect(() => {
        if (
            initiallyEnabled &&
            Array.isArray(getTemplate.data?.getTemplate?.template?.attachments) &&
            attachmentIds.length === 0
        ) {
            setAttachmentIds(getTemplate.data.getTemplate.template.attachments);
        }
    }, [getTemplate.data]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateTemplate = async (
        subject: string,
        body: string,
        newAttachmentIds: FileId[],
    ) => {
        const result = await upsertTemplateMutation.mutateAsync({
            template: {
                body,
                id: value || null,
                description: "",
                subject,
                isDefaultSignature: false,
                isShared: true,
                migrationVersion: 1,
                isSignature: false,
                type: "setting",
                attachments: includeAttachments ? newAttachmentIds : null,
            },
        });

        if (!value && result.createOrUpdateTemplate.template.id) {
            onChange(result.createOrUpdateTemplate.template.id);
        }

        if (value) {
            getTemplate.refetch();

            if (isValidationEnabled) {
                validateTemplateQuery.refetch();
            }
        }
    };

    const handleBodyChanged = async (body: string) => {
        await updateTemplate(
            getTemplate.data?.getTemplate?.template?.subject ?? "",
            body,
            attachmentIds,
        );
    };

    const handleSubjectChanged = async (subject: string) => {
        await updateTemplate(
            subject,
            getTemplate.data?.getTemplate?.template?.body ?? "",
            attachmentIds,
        );
    };

    const handleAttachmentIdsChanged = async (newAttachmentIds: FileId[]) => {
        setAttachmentIds(newAttachmentIds);
        await updateTemplate(
            getTemplate.data?.getTemplate?.template?.subject ?? "",
            getTemplate.data?.getTemplate?.template?.body ?? "",
            newAttachmentIds,
        );
    };

    const validateTemplateQuery = useValidateTemplateQuery(
        {
            emailTemplateId: value,
            messageType: messageType,
        },
        { enabled: isValidationEnabled },
    );

    return waitForLoad ? null : (
        <div className="space-y-2">
            <AutosavingInput
                disabled={disabled}
                label={subjectLabel}
                initial={getTemplate.data?.getTemplate?.template.subject ?? ""}
                onCommit={handleSubjectChanged}
            />
            <HtmlInput
                label={bodyLabel}
                initialContent={getTemplate.data?.getTemplate?.template.body ?? ""}
                onCommit={handleBodyChanged}
                placeholderTypes={placeholderTypes}
            />
            {isValidationEnabled ? (
                <QueryRenderer
                    name="InlineEmailTemplateSetting.validateTemplate"
                    query={validateTemplateQuery}>
                    {data =>
                        data.validateTemplate.isValid ? null : (
                            <InlineBanner type="warning" fontStyle="normal">
                                {data.validateTemplate.message ? (
                                    <div>{data.validateTemplate.message}</div>
                                ) : null}
                                {data.validateTemplate.missingTokens.length > 0 ? (
                                    <ul>
                                        {data.validateTemplate.missingTokens.map(t => (
                                            <li key={t.tokenKey}>
                                                <strong>{t.humanFriendlyName}</strong>
                                                <br />
                                                Email body should include{" "}
                                                <code>{`{{${t.tokenKey}}}`}</code>{" "}
                                                <em>({t.description})</em>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </InlineBanner>
                        )
                    }
                </QueryRenderer>
            ) : null}
            {includeAttachments ? (
                <TemplateAttachments
                    attachmentIds={attachmentIds}
                    onAttachmentIdsChanged={handleAttachmentIdsChanged}
                    label={attachmentLabel}
                />
            ) : null}
        </div>
    );
};
