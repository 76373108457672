import {
    EnvelopeIcon,
    LinkIcon,
    PencilSquareIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {
    AnswerEntityId,
    CommunityId,
    Contact,
    ContactId,
    IAnswer,
    IContact,
    ILocation,
    LayoutSectionKey,
    LayoutSectionParentKey,
    parseEntityId,
    parseLocation,
    QuestionSource,
    ReferenceBusinessId,
    ReferenceContactId,
    UserId,
} from "@sp-crm/core";
import { BylineHeader } from "components/byline/header";
import { ListClient } from "components/clients/list-client";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContactCard } from "components/contacts/card";
import { Activity } from "components/entity-activity/entity-activity";
import { Feature } from "components/feature";
import { FilesControl } from "components/files";
import { SignatureRequestPanel } from "components/files/signature-request/signature-request-panel";
import { Header } from "components/header";
import { Icon } from "components/icon";
import { EntityPageHeader, Stage } from "components/layout";
import { LayoutFormSection } from "components/layout/layout-form-section";
import { MessageCompose } from "components/messages/message-compose";
import { SentMessages } from "components/messages/sent";
import { Notes } from "components/notes/index";
import { ActionBarComponent } from "components/shared/action-bar-component";
import { NgGrid } from "components/shared/grid";
import { PhoneInput } from "components/shared/phone-input";
import { Subnav } from "components/shared/subnav";
import { Tasks } from "components/tasks/child";
import { DeleteButton } from "components/ui/action-button";
import {
    ActionsMenu,
    ActionsMenuAction,
    ActionsMenuSection,
} from "components/ui/actions-menu";
import { AutosavingInput } from "components/ui/autosaving-input";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { SecondaryButton } from "components/ui/secondary-button";
import { navigate } from "store/actions";

import { LayoutSectionResult } from "components/layout/layout-items";
import { getMarkerIconColorFromContractStatus } from "components/map/map-common";
import { NextgenStaticMap } from "components/map/nextgen-static";
import { MarkerIconColor } from "components/map/types";
import { SubnavItem } from "components/shared/subnav/subnav-types";
import { UserSelect } from "components/shared/user-select";
import { Checkbox } from "components/ui/checkbox";
import { ContactNameInput } from "components/ui/contact-name-input";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { Spinner } from "components/ui/spinner";
import {
    AdvancedSearchEntityType,
    FileEntityType,
    GetReferenceContactQuery,
    MutationPatchReferenceContactArgs,
    ReferralBasicsFragment,
    SearchClientsQuery,
    useGetLayoutContainerByKeyQuery,
    useGetReferenceContactQuery,
    useGetReferralsToContactQuery,
    usePatchReferenceContactMutation,
    useSearchClientsQuery,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { loadLeafElementsForEntity } from "store/actions/entity";
import { createChangeRegionAction } from "store/actions/region";
import {
    useCanSeeReferralSourceOwners,
    useFeature,
    useRegionId,
    useRegions,
    useResponsiveMode,
    useTenantSettings,
    useUsers,
} from "store/selectors/hooks";
import { useEntityTasks } from "store/selectors/tasks";
import { ApplicationState } from "store/state";
import { stableQueryOptions } from "util/requests";
import { parseRouteId } from "util/route-id";
import { CreateReferralLinkPanel } from "../create-referral-link-panel";
import { OrganizationSearch } from "../select-reference/organization-search";

type ReferenceContactResult = GetReferenceContactQuery["getReferenceContact"];

const getPreferredName = (reference: ReferenceContactResult) => {
    const contactName = reference.contactEntity.name || "(no name)";

    if (reference.business) {
        return `${contactName} (${reference.business.businessContactEntity.name})`;
    }

    return contactName;
};

interface ReferenceContactHeaderProps {
    reference: ReferenceContactResult;
    onCompose: () => void;
    onDelete: () => void;
    onRequestSignature: () => void;
    onCreateReferralLink: () => void;
}

const ReferenceContactHeader: React.FC<ReferenceContactHeaderProps> = props => {
    const { reference, onCompose, onDelete, onRequestSignature, onCreateReferralLink } =
        props;
    const tenantSettings = useTenantSettings();
    const signWiseEnabled = tenantSettings.enableSignWise;

    return (
        <EntityPageHeader>
            <div className="page-title">
                <h2>
                    <Header iconName="40_ReferralPerson">
                        {getPreferredName(reference)}
                    </Header>
                </h2>
            </div>
            <div className="flex items-center space-x-4">
                <div>
                    <BylineHeader entity={reference} />
                </div>
                <div>
                    <ActionsMenu>
                        <ActionsMenuSection>
                            <ActionsMenuAction Icon={EnvelopeIcon} onClick={onCompose}>
                                Send email
                            </ActionsMenuAction>
                            {signWiseEnabled ? (
                                <ActionsMenuAction
                                    Icon={PencilSquareIcon}
                                    onClick={onRequestSignature}>
                                    Request signature
                                </ActionsMenuAction>
                            ) : null}
                            <ActionsMenuAction
                                Icon={LinkIcon}
                                onClick={onCreateReferralLink}>
                                Share referral link
                            </ActionsMenuAction>
                        </ActionsMenuSection>
                        <ActionsMenuSection>
                            <ActionsMenuAction Icon={TrashIcon} onClick={onDelete}>
                                Delete
                            </ActionsMenuAction>
                        </ActionsMenuSection>
                    </ActionsMenu>
                </div>
            </div>
        </EntityPageHeader>
    );
};

interface ReferenceContactActionBarProps {
    reference: ReferenceContactResult;
    onChange: (args: MutationPatchReferenceContactArgs) => void;
}

const ReferenceContactActionBar: React.FC<ReferenceContactActionBarProps> = props => {
    const { reference, onChange } = props;

    const showAssignedTo = useCanSeeReferralSourceOwners();
    const userState = useUsers();

    const referenceLocation: ILocation | null = useMemo(() => {
        if (reference.business) {
            return parseLocation(
                reference.business.latitude,
                reference.business.longitude,
            ).getOrElse(null);
        }

        if (reference.community) {
            return parseLocation(
                reference.community.latitude,
                reference.community.longitude,
            ).getOrElse(null);
        }

        return null;
    }, [reference.business, reference.community]);

    const markerColor: MarkerIconColor = useMemo(() => {
        if (reference.community) {
            return getMarkerIconColorFromContractStatus(
                reference.community.contractStatus,
            );
        }

        return "violet";
    }, [reference.community]);

    let ownerName = "Unassigned";

    if (reference.ownerId && userState.users[reference.ownerId]) {
        ownerName = userState.users[reference.ownerId].name;
    }

    return (
        <div className="action-bar action-bar-standalone flex">
            <div className="notes-form flex-column widget">
                <span className="glyphicon glyphicon-book" />
                <label htmlFor="edit-reference-summary-notes">
                    <Header iconName="38_ReferralSource">Contact Summary Notes</Header>
                </label>
                <AutosavingInput
                    multiLine={true}
                    initial={reference.summary}
                    onCommit={newVal => onChange({ id: reference.id, summary: newVal })}
                />
            </div>
            {showAssignedTo && (
                <div className="flex-column widget">
                    <ActionBarComponent label="Assigned to" value={ownerName}>
                        <UserSelect
                            includeEveryone={false}
                            includeUnassigned={true}
                            label="Assigned to"
                            onChange={newVal =>
                                onChange({
                                    id: reference.id,
                                    ownerId: (newVal as UserId) || null,
                                })
                            }
                            value={reference.ownerId || ""}
                        />
                    </ActionBarComponent>
                </div>
            )}
            <div className={`flex-column widget map ${showAssignedTo ? "" : "xl:ml-40"}`}>
                {referenceLocation ? (
                    <NextgenStaticMap
                        entityId={reference.business?.id || reference.community?.id}
                        iconType={markerColor}
                        location={referenceLocation}
                    />
                ) : null}
            </div>
        </div>
    );
};

interface ReferenceContactEntityFormProps {
    reference: ReferenceContactResult;
    onChange: (args: MutationPatchReferenceContactArgs) => void;
    onAnswerCommitted: (entityId: AnswerEntityId, answer: IAnswer) => void;
    layoutSection: LayoutSectionResult | null;
}

const ReferenceContactEntityForm: React.FC<ReferenceContactEntityFormProps> = props => {
    const { reference, onChange, onAnswerCommitted, layoutSection } = props;

    const contact = reference.contactEntity;
    return (
        <div className="contacts input-form-block-no-bottom-margin">
            <Header iconName="40_ReferralPerson">Contact Info</Header>
            <div className="space-y-2 lg:space-y-4">
                <ContactNameInput
                    name={contact.name}
                    preferredName={contact.preferredName}
                    onCommit={(fieldName, newValue) =>
                        onChange({
                            id: reference.id,
                            contactEntity: { [fieldName]: newValue },
                        })
                    }
                />
                <AutosavingInput
                    label="Title / Role"
                    initial={contact.role}
                    onCommit={newVal =>
                        onChange({ id: reference.id, contactEntity: { role: newVal } })
                    }
                />
                <div className="flex items-center space-x-2 lg:space-x-4">
                    <PhoneInput
                        label="Cell Phone"
                        initial={contact.cellPhone}
                        onCommit={newVal =>
                            onChange({
                                id: reference.id,
                                contactEntity: { cellPhone: newVal },
                            })
                        }
                    />
                    <PhoneInput
                        label="Alternate Phone"
                        initial={contact.phone1}
                        onCommit={newVal =>
                            onChange({
                                id: reference.id,
                                contactEntity: { phone1: newVal },
                            })
                        }
                    />
                </div>
                <AutosavingInput
                    label="Email Address"
                    initial={contact.email1}
                    onCommit={newVal =>
                        onChange({ id: reference.id, contactEntity: { email1: newVal } })
                    }
                />
                <Feature name="emailoptout">
                    <Checkbox
                        checked={contact.email1OptOut}
                        label="Email Opt-out (for bulk/newsletter emails)"
                        onChange={e =>
                            onChange({
                                id: reference.id,
                                contactEntity: { email1OptOut: e.target.checked },
                            })
                        }
                    />
                </Feature>
            </div>
            <LayoutFormSection
                entityId={reference.id}
                entity={reference}
                entityType="ReferenceContact"
                layoutSection={layoutSection}
                mode="fieldsonly"
                answerMode={QuestionSource.Entity}
                onAnswerCommitted={onAnswerCommitted}
            />
        </div>
    );
};

interface ContactOrganizationHeaderProps {
    reference: ReferenceContactResult;
    onChange: (args: MutationPatchReferenceContactArgs) => void;
}

const ContactOrganizationHeader: React.FC<ContactOrganizationHeaderProps> = props => {
    const { reference, onChange } = props;

    const [showModal, setShowModal] = useState(false);
    const iconName = reference.community ? "57_Community" : "39_Business";
    const title = reference.community
        ? "Associated Community Info"
        : "Associated Organization Info";

    const handleClear = useCallback(() => {
        onChange({ id: reference.id, communityId: null, businessId: null });
    }, [onChange, reference]);

    const handleChoose = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            setShowModal(true);
        },
        [setShowModal],
    );

    const hide = useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    const handleCommunity = useCallback(
        (community: CommunityId) => {
            onChange({ id: reference.id, communityId: community, businessId: null });
            hide();
        },
        [onChange, reference, hide],
    );

    const handleBusiness = useCallback(
        (business: ReferenceBusinessId) => {
            onChange({ id: reference.id, communityId: null, businessId: business });
            hide();
        },
        [onChange, reference, hide],
    );

    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
                <div className="w-7">
                    <Icon name={iconName} />
                </div>
                <span className="text-lg text-gray-700">{title}</span>
            </div>
            <div className="space-x-2 flex items-center">
                {reference.community || reference.business ? (
                    <DeleteButton
                        backgroundColor="bg-white"
                        confirm={{
                            message: "Remove organization from this contact?",
                            title: "Clear organization?",
                            confirmLabel: "Yes, clear",
                        }}
                        onClick={handleClear}
                    />
                ) : null}
                <SecondaryButton onClick={handleChoose}>Choose</SecondaryButton>
            </div>
            <Panel
                isOpen={showModal}
                headerText="Choose Organization"
                type={PanelType.extraLarge}
                onDismiss={hide}>
                <OrganizationSearch
                    onCommunity={handleCommunity}
                    onBusiness={handleBusiness}
                />
            </Panel>
        </div>
    );
};

interface ContactOrganizationPanelProps {
    reference: ReferenceContactResult;
    onChange: (args: MutationPatchReferenceContactArgs) => void;
}

const ContactOrganizationPanel: React.FC<ContactOrganizationPanelProps> = props => {
    const { reference, onChange } = props;

    if (!reference) {
        return null;
    }

    if (reference.business) {
        return (
            <div className="input-form-block-no-bottom-margin">
                <ContactOrganizationHeader reference={reference} onChange={onChange} />
                <ContactCard
                    parentEntityType={AdvancedSearchEntityType.ReferenceBusiness}
                    parentId={reference.business.id}
                    href={`/references/organizations/show/${reference.business.id}`}
                    contact={Contact.load(reference.business.businessContactEntity)}
                />
            </div>
        );
    }

    if (reference.community) {
        const communityAsContact: IContact = Contact.load({
            id: parseEntityId<ContactId>(reference.community.id),
            name: reference.community.name,
            address1: reference.community.address,
            city: reference.community.city,
            state: reference.community.state,
            zip: reference.community.zip,
            phone1: reference.community.mainPhone,
            website: reference.community.website,
            email1: reference.community.email,
        });

        return (
            <div className="input-form-block-no-bottom-margin">
                <ContactOrganizationHeader reference={reference} onChange={onChange} />
                <ContactCard
                    parentEntityType={AdvancedSearchEntityType.Community}
                    parentId={reference.community.id}
                    href={`/communities/show/${reference.community.id}`}
                    contact={communityAsContact}
                />
            </div>
        );
    }

    return (
        <div className="input-form-block-no-bottom-margin">
            <ContactOrganizationHeader reference={reference} onChange={onChange} />
            <p>This contact is not currently associated with an Organization</p>
        </div>
    );
};

interface ReferenceContactInboundReferralsProps {
    reference: ReferenceContactResult;
    clients: SearchClientsQuery["getClients"]["clients"];
}

const ReferenceContactInboundReferrals: React.FC<
    ReferenceContactInboundReferralsProps
> = props => {
    const { reference, clients } = props;

    const users = useUsers().users;
    const responsiveMode = useResponsiveMode();

    if (!reference) {
        return null;
    }

    if (clients.length == 0) {
        return (
            <div>
                <h3>
                    No Inbound Client Referrals have been received from this referral
                    source yet.
                </h3>
                <br />
                <p>
                    Note: when editing a Client profile, if you mark any client&apos;s
                    &quot;Referral Source&quot; as this referral contact, that client will
                    then be listed here.
                </p>
            </div>
        );
    }
    return (
        <div>
            <p>
                All Clients referred to you (or your agency) that have come from this
                referral source ({getPreferredName(reference)}
                ).
            </p>
            <br />

            <ListClient
                showAssignedToColumn={false}
                users={users}
                clients={clients}
                tableShort={true}
                responsiveMode={responsiveMode}
                sourceType={"fromReferral"}
            />
        </div>
    );
};

interface ReferenceContactOutboundReferralsProps {
    outboundClients: ReferralBasicsFragment["client"][];
}

const ReferenceContactOutboundReferrals: React.FC<
    ReferenceContactOutboundReferralsProps
> = props => {
    const { outboundClients } = props;

    const users = useUsers().users;
    const responsiveMode = useResponsiveMode();

    if (outboundClients.length == 0) {
        return (
            <div>
                <h3>
                    No Outbound Client Referrals have been linked to this referral source
                    yet.
                </h3>
                <br />
                <p>
                    Note: when editing a Client profile, if you add a &quot;Professional
                    Referral&quot; and choose this referral source, that client will then
                    be listed here.
                </p>
                <p>
                    Tracking your Outbound Professional Referrals can help you identify
                    how often you are referring to this referral source vs. how often they
                    are referring to you.
                </p>
            </div>
        );
    }
    return (
        <div>
            <p>
                All Clients you have referred out to this contact (marked as
                &quot;Professional Referrals&quot; on a Client profile).
            </p>
            <br />

            <ListClient
                showAssignedToColumn={false}
                users={users}
                clients={outboundClients}
                tableShort={true}
                responsiveMode={responsiveMode}
                sourceType={"fromReferral"}
            />
        </div>
    );
};

interface ReferenceContactOverviewProps {
    reference: ReferenceContactResult;
    onChange: (args: MutationPatchReferenceContactArgs) => Promise<void>;
    onAnswerCommitted: (entityId: AnswerEntityId, answer: IAnswer) => void;
}

const ReferenceContactOverview: React.FC<ReferenceContactOverviewProps> = props => {
    const { reference, onChange, onAnswerCommitted } = props;

    const getLayoutContainer = useGetLayoutContainerByKeyQuery(
        { key: LayoutSectionParentKey.ReferenceContact },
        stableQueryOptions(),
    );

    const contactInformationSection =
        getLayoutContainer.data?.getLayoutContainerByKey.layoutSections.find(
            s => s.sectionKey === LayoutSectionKey.ReferenceContactContactInformation,
        );
    const additionalInformationSection =
        getLayoutContainer.data?.getLayoutContainerByKey.layoutSections.find(
            s => s.sectionKey === LayoutSectionKey.ReferenceContactAdditionalInformation,
        );

    if (!reference) {
        return null;
    }

    return (
        <NgGrid>
            <NgGrid.Column>
                <ReferenceContactEntityForm
                    reference={reference}
                    onChange={onChange}
                    onAnswerCommitted={onAnswerCommitted}
                    layoutSection={contactInformationSection}
                />
                <ContactOrganizationPanel reference={reference} onChange={onChange} />
            </NgGrid.Column>
            <NgGrid.Column>
                <Notes entityId={reference.id} />
                <LayoutFormSection
                    entityId={reference.id}
                    entity={reference}
                    entityType="ReferenceContact"
                    layoutSection={additionalInformationSection}
                    mode="tile"
                    answerMode={QuestionSource.Entity}
                    onAnswerCommitted={onAnswerCommitted}
                />
            </NgGrid.Column>
        </NgGrid>
    );
};

interface ShowReferenceContactInternalProps {
    referenceId: ReferenceContactId;
    subpage?: string;
}

const ShowReferenceContactInternal: React.FC<
    ShowReferenceContactInternalProps
> = props => {
    const { referenceId, subpage } = props;

    const getReferenceContactQuery = useGetReferenceContactQuery(
        { id: referenceId },
        { keepPreviousData: true },
    );
    const getOutboundReferralsQuery = useGetReferralsToContactQuery({
        referenceContactId: referenceId,
    });
    const reference = getReferenceContactQuery.data?.getReferenceContact;
    const patchReferenceContactMutation = usePatchReferenceContactMutation();
    const [composing, setComposing] = useState(false);
    const [requestSignature, setReqesutSignature] = useState(false);
    const [createReferralLink, setCreateReferralLink] = useState(false);
    const client = useQueryClient();
    const faxEnabled = useFeature("fax");
    const files = useSelector(
        (state: ApplicationState) =>
            (state.files.files && state.files.files[referenceId]) || [],
    );
    const { tasks, refetchTasks } = useEntityTasks(referenceId);
    const activeTasks = useMemo(() => {
        return tasks.filter(t => t.active());
    }, [tasks]);
    const dispatch = useDispatch();

    const regionId = useRegionId();
    const selectedReferenceRegionId = useRegionId("reference");
    const regions = useRegions();

    useEffect(() => {
        loadLeafElementsForEntity(referenceId, dispatch);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            selectedReferenceRegionId &&
            reference?.regionId &&
            selectedReferenceRegionId !== reference?.regionId
        ) {
            const newRegionKey = regions.find(
                r => r.referenceRegionId === reference?.regionId,
            )?.key;
            if (newRegionKey) {
                dispatch(createChangeRegionAction(newRegionKey));
            }
        }
    }, [reference?.regionId, selectedReferenceRegionId]); // eslint-disable-line react-hooks/exhaustive-deps

    const getReferredClientsQuery = useSearchClientsQuery(
        {
            regionId,
            page: 1,
            perPage: 1000,
            referralContactId: referenceId,
        },
        {
            keepPreviousData: true,
        },
    );

    const inboundClients: SearchClientsQuery["getClients"]["clients"] = useMemo(() => {
        if (!referenceId || !getReferredClientsQuery.data?.getClients?.clients) {
            return [];
        }

        return getReferredClientsQuery.data.getClients.clients;
    }, [referenceId, getReferredClientsQuery.data]);

    const outboundClients = useMemo(() => {
        if (!referenceId || !getOutboundReferralsQuery.data) {
            return [];
        }

        return getOutboundReferralsQuery.data.getReferralsToContact
            .map(r => r.client)
            .filter(c => !!c);
    }, [referenceId, getOutboundReferralsQuery.data]);

    const handleReferenceChange = useCallback(
        async (args: MutationPatchReferenceContactArgs) => {
            const result = await patchReferenceContactMutation.mutateAsync(args);

            if (result?.patchReferenceContact) {
                client.setQueryData<GetReferenceContactQuery>(
                    ["getReferenceContact", { id: referenceId }],
                    oldData => {
                        return produce(oldData, draft => {
                            if (draft) {
                                draft.getReferenceContact = result.patchReferenceContact;
                            }
                        });
                    },
                );
            }
        },
        [patchReferenceContactMutation, client, referenceId],
    );

    const handleAnswerCommitted = useCallback(
        (entityId: AnswerEntityId, answer: IAnswer) => {
            getReferenceContactQuery.refetch();
        },
        [getReferenceContactQuery],
    );

    const renderOverview = useCallback(() => {
        return (
            <ReferenceContactOverview
                reference={reference}
                onChange={handleReferenceChange}
                onAnswerCommitted={handleAnswerCommitted}
            />
        );
    }, [reference, handleReferenceChange, handleAnswerCommitted]);

    const renderInboundReferrals = useCallback(() => {
        if (!getReferredClientsQuery.data) {
            return <Spinner />;
        }

        return (
            <ReferenceContactInboundReferrals
                reference={reference}
                clients={inboundClients}
            />
        );
    }, [reference, inboundClients, getReferredClientsQuery]);

    const renderOutboundReferrals = useCallback(() => {
        return <ReferenceContactOutboundReferrals outboundClients={outboundClients} />;
    }, [outboundClients]);

    const renderMessages = useCallback(() => {
        return (
            <SentMessages
                entityId={referenceId}
                showClient={false}
                showCommunity={false}
            />
        );
    }, [referenceId]);

    const renderTasks = useCallback(() => {
        return (
            <NgGrid>
                <NgGrid.Column>
                    <Tasks parentId={referenceId} tasks={tasks} refetch={refetchTasks} />
                </NgGrid.Column>
            </NgGrid>
        );
    }, [referenceId, tasks, refetchTasks]);

    const renderFiles = useCallback(() => {
        return (
            <FilesControl entityId={referenceId} entityType={FileEntityType.Referral} />
        );
    }, [referenceId]);
    const renderActivity = useCallback(() => {
        return <Activity entityType="ReferenceContact" entityId={referenceId} />;
    }, [referenceId]);

    const handleNavigate = useCallback(
        (path: string) => {
            navigate(`/references/contacts/show/${referenceId}/${path}`);
        },
        [referenceId],
    );

    const handleCloseSection = useCallback(() => {
        navigate(`/references/contacts/show/${referenceId}`);
    }, [referenceId]);

    const handleCompose = useCallback(() => {
        setComposing(true);
    }, [setComposing]);

    const handleComposeDismiss = useCallback(() => {
        setComposing(false);
    }, [setComposing]);

    const handleRequestSignature = useCallback(() => {
        setReqesutSignature(true);
    }, []);
    const dismissRequestSignature = useCallback(() => {
        setReqesutSignature(false);
    }, []);

    const handleCreateReferralLink = useCallback(() => {
        setCreateReferralLink(true);
    }, []);
    const dismissCreateReferralLink = useCallback(() => {
        setCreateReferralLink(false);
    }, []);

    const handleDelete = useCallback(async () => {
        if (reference) {
            const shouldDelete = await fancyConfirm(
                `Delete ${getPreferredName(reference)}?`,
                `Really delete ${getPreferredName(reference)}?`,
                "Yes, delete",
                "Cancel",
            );
            if (shouldDelete) {
                await handleReferenceChange({ id: reference.id, deleted: true });
                navigate(`/references`);
            }
        }
    }, [reference, handleReferenceChange]);

    return (
        <QueryRenderer
            query={getReferenceContactQuery}
            name="ShowReferenceContactInternal.getReferenceContact">
            {data => {
                const subpages = [
                    {
                        href: `/references/contacts/show/${referenceId}/overview`,
                        linkText: "Contact Overview",
                        subpage: "overview",
                        render: renderOverview,
                    },
                    {
                        href: `/references/contacts/show/${referenceId}/inbound`,
                        linkText: `Inbound Referrals`,
                        badgeCount: inboundClients.length,
                        subpage: "inbound",
                        render: renderInboundReferrals,
                    },
                    {
                        href: `/references/contacts/show/${referenceId}/outbound`,
                        linkText: `Outbound Referrals`,
                        badgeCount: outboundClients.length,
                        subpage: "outbound",
                        render: renderOutboundReferrals,
                    },
                    {
                        href: `/references/contacts/show/${referenceId}/emails`,
                        linkText: faxEnabled ? "Messages" : `Emails Sent`,
                        subpage: "emails",
                        render: renderMessages,
                    },
                    {
                        href: `/references/contacts/show/${referenceId}/tasks`,
                        linkText: "Tasks",
                        subpage: "tasks",
                        render: renderTasks,
                        badgeCount: activeTasks.length || undefined,
                        badgeType: activeTasks.some(t => t.overdue()) ? "error" : "info",
                    } as SubnavItem,
                    {
                        href: `/references/contacts/show/${referenceId}/files`,
                        subpage: "files",
                        linkText: `Files`,
                        badgeCount: files.length,
                        render: renderFiles,
                    },
                    {
                        href: `/references/contacts/show/${referenceId}/activity`,
                        subpage: "activity",
                        linkText: `Activity`,
                        render: renderActivity,
                    },
                ].filter(Boolean);

                const bgClass = ["files", "tasks"].includes(subpage) ? "bg-white" : "";
                return reference.deleted ? (
                    <InlineBanner type="error">
                        This contact has been deleted and its information is no longer
                        available
                    </InlineBanner>
                ) : (
                    <>
                        <ReferenceContactHeader
                            reference={data.getReferenceContact}
                            onCompose={handleCompose}
                            onDelete={handleDelete}
                            onRequestSignature={handleRequestSignature}
                            onCreateReferralLink={handleCreateReferralLink}
                        />
                        {composing ? (
                            <MessageCompose
                                entityId={referenceId}
                                entityType={AdvancedSearchEntityType.ReferenceContact}
                                onDismiss={handleComposeDismiss}
                            />
                        ) : null}
                        <SignatureRequestPanel
                            fileEntityType={FileEntityType.Referral}
                            entityId={referenceId}
                            onDismiss={dismissRequestSignature}
                            isOpen={requestSignature}
                        />
                        {createReferralLink ? (
                            <CreateReferralLinkPanel
                                referenceBusinessId={reference.businessId}
                                referenceContactId={reference.id}
                                referenceCommunityId={reference.communityId}
                                onDismiss={dismissCreateReferralLink}
                            />
                        ) : null}
                        <ReferenceContactActionBar
                            reference={data.getReferenceContact}
                            onChange={handleReferenceChange}
                        />

                        <div
                            className={`col main-body full-width ${bgClass} min-h-screen`}>
                            <Subnav
                                subpages={subpages}
                                selectedSubpage={subpage}
                                defaultSubpage="overview"
                                navigate={handleNavigate}
                                closeSection={handleCloseSection}
                            />
                        </div>
                    </>
                );
            }}
        </QueryRenderer>
    );
};

type ShowContactReferenceRouteMatch = {
    params?: { referenceId: string; subpage?: string };
} | null;

export const ShowContactReference: React.FC<unknown> = () => {
    const routeMatch: ShowContactReferenceRouteMatch = useRouteMatch();

    const referenceId = routeMatch?.params?.referenceId
        ? parseRouteId<ReferenceContactId>(
              "ReferenceContact",
              routeMatch.params.referenceId,
          )
        : null;

    if (referenceId) {
        return (
            <Stage>
                <ShowReferenceContactInternal
                    referenceId={referenceId}
                    subpage={routeMatch.params?.subpage}
                />
            </Stage>
        );
    }

    return null;
};
