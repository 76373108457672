import { ActionType, UserId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader } from "components/layout";
import { CrmTable, CrmTableProps } from "components/table/crm-table";
import { InlineBanner } from "components/ui/inline-banner";
import { defaultLinkStyle } from "components/ui/link";
import { PremiumCallout } from "components/ui/premium-callout";
import {
    UserManagementGetUsersQuery,
    useUserManagementGetIntegrationUsersQuery,
    useUserManagementGetRolesQuery,
} from "generated/graphql";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useProductName } from "store/selectors/branding";
import { useFeature, useRegionsWithPermission } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";
import { statusForUser } from "../manage-users/helper";
import { UserPermissionsCell } from "../manage-users/user-permissions-cell";
import { IntegrationUserApiKey } from "./integration-user-api-key";
import { ManageIntegrationsNewAccount } from "./manage-integrations-new";

interface IntegrationUser {
    id: UserId;
    name: string;
    status: string;
    placeholder: unknown;
    permissions: UserManagementGetUsersQuery["userManagementGetUsers"][0]["user"]["permissions"];
    disabled: boolean;
}

const initialSort = [
    {
        id: "name",
        desc: false,
    },
];

const generateIntegrationUser = (
    user: UserManagementGetUsersQuery["userManagementGetUsers"][0],
): IntegrationUser => ({
    id: user.user.id,
    name: user.user.name,
    status: statusForUser(user.user),
    permissions: user.user.permissions,
    placeholder: null,
    disabled: user.user.disabled,
});

export const ManageIntegrationsList: React.FC<unknown> = () => {
    const users = useUserManagementGetIntegrationUsersQuery(
        {},
        { keepPreviousData: true },
    );

    const roles = useUserManagementGetRolesQuery({}, stableQueryOptions());
    const regionsWithModifyAccess = useRegionsWithPermission(
        ActionType.ManageIntegrations,
    );
    const isFeatureEnabled = useFeature("integrationAccounts");
    const productName = useProductName();

    const columns: CrmTableProps<IntegrationUser>["columns"] = useMemo(
        () => [
            {
                key: "placeholder",
                header: "",
                renderCell: ({ record }) => (
                    <Link
                        to={`/settings/integrations/accounts/${record.id}`}
                        className={defaultLinkStyle}>
                        Edit
                    </Link>
                ),
                width: 20,
            },
            {
                key: "name",
                header: "Name",
            },
            {
                key: "status",
                header: "Status",
            },
            {
                key: "permissions",
                header: "Permissions",
                enableSorting: false,
                renderCell: ({ record }) => {
                    return (
                        <UserPermissionsCell
                            regions={regionsWithModifyAccess}
                            permissions={record.permissions}
                            roles={roles.data?.userManagementGetRoles}
                        />
                    );
                },
            },
            {
                key: "id",
                header: "API Key",
                enableSorting: false,
                renderCell: ({ record }) => {
                    return record.disabled ? null : (
                        <IntegrationUserApiKey userId={record.id} />
                    );
                },
            },
        ],
        [roles.data?.userManagementGetRoles, regionsWithModifyAccess],
    );

    return (
        <QueryRenderer name="ManageIntegrationsList.getUsers" query={users}>
            {data => {
                return (
                    <div className="space-y-4 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <div className="lg:col-span-8">
                            <ContentFull>
                                <ContentHeader>Integration Accounts</ContentHeader>
                                <div className="space-y-2">
                                    <p>
                                        Integration accounts give other applications
                                        access to your {productName} data. These are
                                        typically used to integrate {productName} with
                                        Zapier.
                                    </p>
                                    {isFeatureEnabled ? (
                                        data.userManagementGetUsers.length === 0 ? (
                                            <p>{`No integration accounts have been created yet. Use the "Create new integration account" box to create a new account`}</p>
                                        ) : (
                                            <>
                                                <InlineBanner type="info">
                                                    When using API Keys from the
                                                    integration accounts below, remember
                                                    to keep them secret and safe like you
                                                    would a password.
                                                </InlineBanner>
                                                <CrmTable
                                                    initialSort={initialSort}
                                                    columns={columns}
                                                    data={data.userManagementGetUsers.map(
                                                        generateIntegrationUser,
                                                    )}
                                                />
                                            </>
                                        )
                                    ) : (
                                        <PremiumCallout>
                                            <InlineBanner type="info">
                                                Integration accounts are only available on
                                                request to {productName} Premium
                                                customers. Contact support to request an
                                                integration account.
                                            </InlineBanner>
                                        </PremiumCallout>
                                    )}
                                </div>
                            </ContentFull>
                        </div>
                        <div className="lg:col-span-4">
                            {isFeatureEnabled ? (
                                <ContentFull>
                                    <ContentHeader>
                                        Create new integration account
                                    </ContentHeader>
                                    <ManageIntegrationsNewAccount
                                        onCreated={() => {
                                            users.refetch();
                                        }}
                                    />
                                </ContentFull>
                            ) : null}
                        </div>
                    </div>
                );
            }}
        </QueryRenderer>
    );
};
