import * as C from "@sp-crm/core";
import { Maybe } from "@sp-crm/core";
import { produce } from "immer";
import { Action } from "redux";
import { Actions } from "../actions";
import { ChangeRegionAction } from "../actions/region";

export interface RegionState {
    selectedRegion: string;
    regions: C.TenantRegion[];
    explicitRegionSelected: boolean;
}

export function regionReducer(originalState: RegionState, action: Action): RegionState {
    if (!originalState) {
        originalState = {
            selectedRegion: null,
            regions: [],
            explicitRegionSelected: false,
        };
    }
    return produce(originalState, state => {
        if (action.type === Actions[Actions.LOGOUT]) {
            state.regions = [];
        }
        if (action.type === Actions[Actions.LOAD_TENANT_FINISH]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            const loadedTenant = C.Tenant.fromDocument((action as any).tenant);
            state.regions = loadedTenant.regions || [];
            if (
                state.explicitRegionSelected &&
                state.selectedRegion &&
                !state.regions.find(r => r.key === state.selectedRegion)
            ) {
                state.selectedRegion = null;
                state.explicitRegionSelected = false;
            }
        }

        if (action.type === Actions[Actions.SELECT_REGION]) {
            const castAction = action as ChangeRegionAction;
            state.selectedRegion = castAction.selectedRegion;
            state.explicitRegionSelected = true;
        }

        if (!state.selectedRegion && state.regions.length > 0) {
            state.selectedRegion = Maybe.fromValue(state.regions[0])
                .map(r => r.key)
                .getOrElse(null);
        }
    });
}
